import { Route, Routes } from 'react-router-dom';
import CategoryList from './category-list';
import CategoryAddEdit from './category-add-edit';

export default function Category() {
  return (
    <Routes>
      <Route id="CategoryList" path="/" element={<CategoryList />} />
      <Route id="CategoryAdd" path="/add" element={<CategoryAddEdit />} />
      <Route
        id="CategoryEdit"
        path="/:customerId"
        element={<CategoryAddEdit />}
      />
    </Routes>
  );
}
