import { useToast } from '@/components/ui/use-toast';
import { customerService } from '@/lib/services/customer';
import { customerBillService } from '@/lib/services/customer-bill';
import { paymentService } from '@/lib/services/payment';
import { useMutation, useQuery } from 'react-query';

const useCustomerBill = () => {
  const queryKey = 'billCustomerList';
  const { toast } = useToast();
  // List query
  const list = (customerId: string) => {
    return useQuery(
      [queryKey, customerId],
      () => customerBillService.list(customerId),
      {
        keepPreviousData: true,
        staleTime: Infinity,
      },
    );
  };

  // Read mutation
  const readCustomerMutation = useMutation(customerService.read, {
    onError: () => {
      toast({
        title: 'Ooops!',
        description: `Houve um erro ao buscar cliente`,
      });
    },
  });

  const readCustomerBillMutation = useMutation(customerBillService.readBill, {
    onError: () => {
      toast({
        title: 'Ooops!',
        variant: 'destructive',
        description: `Houve um erro ao encontrar conta`,
      });
    },
  });

  const readPayment = (id: string) =>
    useQuery(['readPayment', id], () => paymentService.findOne(id), {
      refetchInterval: 60000,
      enabled: !!id,
    });

  return {
    list,
    readCustomerMutation,
    readCustomerBillMutation,
    readPayment,
  };
};

export default useCustomerBill;
