import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input, TextArea } from '@/components/ui/input';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import Loader from '@/components/loader';
import useCategory from '@/hooks/useCategory';

const formSchema = z.object({
  name: z.string().min(1, 'O nome é obrigatório'),
  color: z.string().min(1, 'A cor é obrigatória'),
  description: z.string().optional(),
});

export default function CategoryAddEdit() {
  const { customerId } = useParams();
  const { createMutation, readMutation, updateMutation } = useCategory();
  const navigate = useNavigate();

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      description: '',
      color: '',
    },
  });

  useEffect(() => {
    fechCustomer();
  }, [customerId]);

  const fechCustomer = async () => {
    if (customerId) {
      const data = await readMutation.mutateAsync(customerId);
      form.reset({ ...data, description: data.description || '' });
    }
  };

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    if (!customerId) {
      await createMutation.mutateAsync({ ...values });
    } else {
      await updateMutation.mutateAsync({ id: customerId, data: { ...values } });
    }

    navigate('/categories');
  };

  const renderHeader = useMemo(() => {
    return (
      <CardHeader>
        <CardTitle>
          {!customerId ? 'Adicionar categoria' : 'Editar categoria'}
        </CardTitle>
        {!customerId && (
          <CardDescription>
            Insira todos os dados abaixo para cadastrar um nova categoria.
          </CardDescription>
        )}
      </CardHeader>
    );
  }, [customerId]);
  return (
    <div className="grid auto-rows-max items-start gap-4 md:gap-2 lg:col-span-3">
      <Card>
        {renderHeader}
        <CardContent>
          {readMutation.isLoading ? (
            <Loader />
          ) : (
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-8"
              >
                <div className="grid lg:grid-cols-2 gap-3">
                  <FormField
                    control={form.control}
                    name="name"
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Nome</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="color"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Cor</FormLabel>
                        <FormControl>
                          <Input {...field} type="color" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="description"
                    render={({ field }) => (
                      <FormItem className="col-span-3">
                        <FormLabel>Descrição</FormLabel>
                        <FormControl>
                          <TextArea
                            {...field}
                            type="text"
                            className="min-h-10"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="flex flex-row gap-2 items-center justify-end">
                  {!createMutation.isLoading && (
                    <Button type="button" variant="outline" asChild>
                      <Link to={'/categories'}>Cancelar</Link>
                    </Button>
                  )}
                  <Button
                    type="submit"
                    loading={
                      createMutation.isLoading || updateMutation.isLoading
                    }
                  >
                    Salvar
                  </Button>
                </div>
              </form>
            </Form>
          )}
        </CardContent>
      </Card>
    </div>
  );
}
