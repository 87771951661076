import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useCustomer from './hooks/useCustomer';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import Loader from '@/components/loader';

const formSchema = z.object({
  name: z.string().min(1, 'O nome é obrigatório'),
  email: z.union([z.literal(null), z.literal(''), z.string().email()]),
});

export default function CustomerAddEdit() {
  const { customerId } = useParams();
  const navigate = useNavigate();
  const { createMutation, readMutation, updateMutation } = useCustomer();

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      email: '',
    },
  });

  useEffect(() => {
    fechCustomer();
  }, [customerId]);

  const fechCustomer = async () => {
    if (customerId) {
      const data = await readMutation.mutateAsync(customerId);
      form.reset({ ...data });
    }
  };

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      if (!customerId) {
        await createMutation.mutateAsync({ ...values });
      } else {
        await updateMutation.mutateAsync({
          id: customerId,
          data: { ...values },
        });
      }
    } finally {
      navigate('/customers');
    }
  };

  const renderHeader = useMemo(() => {
    return (
      <CardHeader>
        <CardTitle>
          {!customerId ? 'Adicionar cliente' : 'Editar cliente'}
        </CardTitle>
        {!customerId && (
          <CardDescription>
            Insira todos os dados abaixo para cadastrar um novo cliente.
          </CardDescription>
        )}
      </CardHeader>
    );
  }, [customerId]);
  return (
    <div className="grid auto-rows-max items-start gap-4 md:gap-2 lg:col-span-3">
      <Card>
        {renderHeader}
        <CardContent>
          {readMutation.isLoading ? (
            <Loader />
          ) : (
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-8"
              >
                <div className="grid lg:grid-cols-2 gap-3">
                  <FormField
                    control={form.control}
                    name="name"
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Nome</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>E-mail</FormLabel>
                        <FormControl>
                          <Input {...field} type="text" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="flex flex-row gap-2 items-center justify-end">
                  {!createMutation.isLoading && (
                    <Button type="button" variant="outline" asChild>
                      <Link to={'/customers'}>Cancelar</Link>
                    </Button>
                  )}
                  <Button
                    type="submit"
                    loading={
                      createMutation.isLoading || updateMutation.isLoading
                    }
                  >
                    Salvar
                  </Button>
                </div>
              </form>
            </Form>
          )}
        </CardContent>
      </Card>
    </div>
  );
}
