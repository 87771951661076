import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import { FormControl } from '@/components/ui/form';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import useCategory from '@/hooks/useCategory';
import { cn } from '@/lib/utils';
import { Check, ChevronDown, Loader2 } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';

type CategoryComboboxProps = {
  value: string;
  onChange: (value: string) => void;
};

export const CategoryCombobox = ({
  value,
  onChange,
}: CategoryComboboxProps) => {
  const { listPaginated } = useCategory();
  const { data, isLoading } = listPaginated();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [categories, setCategories] = useState<
    { label: string; value: string }[]
  >([]);
  useEffect(() => {
    if (data && data.length > 0) {
      const mapped = data.map((category: any) => ({
        label: category.name,
        value: String(category.id),
      }));
      setCategories(mapped);
    }
  }, [data]);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <FormControl>
          <Button
            variant="outline"
            role="combobox"
            disabled={isLoading}
            className={cn(
              'justify-between w-full',
              !value && 'text-muted-foreground',
            )}
            ref={buttonRef}
          >
            {value
              ? categories.find((category) => category.value === value)?.label
              : 'Selecione uma categoria'}

            {isLoading ? (
              <Loader2 className="w-5 h-5 animate-spin" />
            ) : (
              <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            )}
          </Button>
        </FormControl>
      </PopoverTrigger>
      <PopoverContent
        className={cn('p-0')}
        style={{ width: buttonRef.current?.clientWidth }}
      >
        <Command>
          <CommandInput placeholder="Pesquisar uma categoria..." />
          <CommandList>
            <CommandEmpty>Nenhuma categoria.</CommandEmpty>
            <CommandGroup>
              {categories.map((category) => {
                return (
                  <CommandItem
                    value={category.value}
                    key={category.value}
                    onSelect={() => {
                      onChange(category.value);
                    }}
                  >
                    <Check
                      className={cn(
                        'mr-2 h-4 w-4',
                        category.value === value ? 'opacity-100' : 'opacity-0',
                      )}
                    />
                    {category.label}
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
