import { useToast } from '@/components/ui/use-toast';
import { accountService } from '@/lib/services/account';
import { useMutation, useQuery, useQueryClient } from 'react-query';

const useAccount = () => {
  const queryClient = useQueryClient();
  const { toast } = useToast();
  const queryKey = 'accountListData';

  // Create Mutation
  const createMutation = useMutation(accountService.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      toast({
        title: 'Sucesso!',
        variant: 'success',
        description: `Conta criada com sucesso.`,
      });
    },
    onError: () => {
      toast({
        title: 'Ooops!',
        variant: 'destructive',
        description: `Houve um erro ao criar uma conta.`,
      });
    },
  });

  // Read mutation
  const readMutation = useMutation(accountService.read, {
    onError: () => {
      toast({
        title: 'Ooops!',
        variant: 'destructive',
        description: `Houve um erro ao criar uma conta.`,
      });
    },
  });

  // List query
  const listPaginated = () => {
    return useQuery([queryKey], () => accountService.list(), {
      keepPreviousData: true,
      staleTime: Infinity,
    });
  };

  // Update Mutation
  const updateMutation = useMutation(
    ({ id, data }: { id: string; data: any }) =>
      accountService.update(id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
    },
  );

  // Delete Mutation
  const deleteMutation = useMutation(accountService.delete, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });

  return {
    createMutation,
    readMutation,
    listPaginated,
    updateMutation,
    deleteMutation,
  };
};

export default useAccount;
