import { Button } from '@/components/ui/button';
import InputWithMask, { Input, InputFrame } from '@/components/ui/input';
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';
import { validateCpf } from '@/lib/utils';
import { Bill } from '@/models/bill';
import { Installment } from '@/models/installment';
import { CheckSquare2Icon, Square } from 'lucide-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  initMercadoPago,
  createCardToken,
  CardNumber,
  SecurityCode,
  ExpirationDate,
} from '@mercadopago/sdk-react';
import _ from 'lodash';
import { Label } from '@/components/ui/label';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { paymentService } from '@/lib/services/payment';
import { useTheme } from '@/components/theme-provider';
import { useToast } from '@/components/ui/use-toast';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { useNavigate, useParams } from 'react-router-dom';

const formSchema = z.object({
  ticketFirstName: z.string().min(1, 'O nome é obrigatório'),
  ticketLastName: z.string().min(1, 'O sobrenome é obrigatório'),
  ticketIdentificationNumber: z
    .string()
    .min(1, 'O CPF é um campo obrigatório')
    .refine(validateCpf, {
      message: 'O CPF é inválido',
    }),
});

export const PaymentCheckout = ({
  bill,
  installment,
  paymentMethods,
}: {
  bill: Bill;
  installment?: Installment;
  paymentMethods: any[];
}) => {
  const { theme } = useTheme();
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const isValid = useRef(false);
  const navigate = useNavigate();
  const { customerId } = useParams();
  // Card
  const cardButtonRef = useRef<
    HTMLButtonElement & {
      setLoading?: (value: boolean) => void;
      toggleDisabled?: () => void;
    }
  >(null);
  const cardNumberRef = useRef<HTMLDivElement>(null);
  const cardExpirateDateRef = useRef<HTMLDivElement>(null);
  const cardSecurityCodeRef = useRef<HTMLDivElement>(null);
  const cardholderNameRef = useRef<HTMLInputElement>(null);
  const cardholderIdentificationNumberRef = useRef<HTMLInputElement>(null);
  const errorCardNumber = useRef<HTMLSpanElement>(null);
  const errorExpirateDate = useRef<HTMLSpanElement>(null);
  const errorSecurityCode = useRef<HTMLSpanElement>(null);
  const errorCardholderName = useRef<HTMLSpanElement>(null);
  const errorCardholderIdentificationNumber = useRef<HTMLSpanElement>(null);

  const [cardNumberState, setCardNumberState] = useState({
    valid: false,
    errorRef: errorCardNumber,
    ref: cardNumberRef,
    dirty: false,
  });

  const [expirateDateState, setExpirateDateState] = useState({
    valid: false,
    errorRef: errorExpirateDate,
    ref: cardExpirateDateRef,
    dirty: false,
  });

  const [securityCodeState, setSecurityCodeState] = useState({
    valid: false,
    errorRef: errorSecurityCode,
    ref: cardSecurityCodeRef,
    dirty: false,
  });

  const [cardholderNameState, setCardholderNameState] = useState({
    value: '',
    valid: false,
    errorRef: errorCardholderName,
    ref: cardholderNameRef,
    dirty: false,
  });

  const [
    cardholderIdentificationNumberState,
    setCardholderIdentificationNumberState,
  ] = useState({
    value: '',
    valid: false,
    errorRef: errorCardholderIdentificationNumber,
    ref: cardholderIdentificationNumberRef,
    dirty: false,
  });

  // Ticket
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      ticketFirstName: '',
      ticketLastName: '',
      ticketIdentificationNumber: '',
    },
    mode: 'onChange',
  });

  const [paymentMethod, setPaymentMethod] = useState('debit_card');

  const prefersDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)',
  ).matches;
  const styles = {
    height: '22px',
    color:
      theme === 'light'
        ? '#3333333'
        : theme === 'dark'
          ? 'white'
          : prefersDarkMode
            ? 'white'
            : '#3333333',
    fontSize: '14px',
    placeholderColor: 'gray',
  };

  useEffect(() => {
    initMercadoPago(import.meta.env.VITE_TOKEN_ML);
  }, []);

  const handleErrorMessage = (
    errorMessage: string,
    errorRef: any,
    field: any,
  ) => {
    if (errorMessage.length > 0) {
      if (errorRef.current) {
        errorRef.current.innerText = errorMessage;
      }
      if (field) {
        field.classList.add('!border-red-500');
      }
    } else {
      if (errorRef.current) {
        errorRef.current.innerText = '';
      }
      if (field) {
        field.classList.remove('!border-red-500');
      }
    }
  };

  const handleChange = (field: string, value: any) => {
    switch (field) {
      case 'cardholderIdentificationNumber':
        setCardholderIdentificationNumberState((prevState) => {
          prevState.value = value;
          let errorMessage = '';
          const field = prevState.ref.current;
          if (value.length > 0) {
            prevState.dirty = true;
          }

          if (prevState.dirty) {
            if (prevState.dirty && value.length <= 0) {
              errorMessage = 'O CPF é obrigatório';
            }

            if (
              prevState.dirty &&
              prevState.value.replace(/[.-]/g, '').length < 11
            ) {
              errorMessage = 'O CPF é obrigatório';
            }

            if (
              prevState.dirty &&
              prevState.value.replace(/[.-]/g, '').length == 11 &&
              !validateCpf(value)
            ) {
              errorMessage = 'CPF inválido';
            }
          }

          handleErrorMessage(errorMessage, prevState.errorRef, field);
          if (errorMessage.length > 0) {
            prevState.valid = false;
          } else {
            prevState.valid = true;
          }

          return { ...prevState };
        });
        break;
      case 'cardholderName':
        setCardholderNameState((prevState) => {
          prevState.value = value;
          let errorMessage = '';
          const field = prevState.ref.current;
          if (value.length > 0) {
            prevState.dirty = true;
          }

          if (prevState.dirty) {
            if (prevState.dirty && value.length <= 0) {
              errorMessage = 'O nome do titular é obrigatório';
            }
          }

          handleErrorMessage(errorMessage, prevState.errorRef, field);
          if (errorMessage.length > 0) {
            prevState.valid = false;
          } else {
            prevState.valid = true;
          }

          return { ...prevState };
        });
        break;
      case 'cardNumber':
        setCardNumberState((prevState) => {
          let errorMessage = '';
          const field = prevState.ref.current;
          if (!_.isNil(value)) {
            prevState.dirty = true;
          }

          if (prevState.dirty) {
            if (
              !_.isNil(value?.errorMessages) &&
              value?.errorMessages.length > 0
            ) {
              errorMessage = value.errorMessages[0].message;
            }
          }

          handleErrorMessage(errorMessage, prevState.errorRef, field);
          if (errorMessage.length > 0) {
            prevState.valid = false;
          } else {
            prevState.valid = true;
          }

          return { ...prevState };
        });
        break;
      case 'cardExpirationDate':
        setExpirateDateState((prevState) => {
          let errorMessage = '';
          const field = prevState.ref.current;
          if (!_.isNil(value)) {
            prevState.dirty = true;
          }

          if (prevState.dirty) {
            if (
              !_.isNil(value?.errorMessages) &&
              value?.errorMessages.length > 0
            ) {
              errorMessage = value.errorMessages[0].message;
            }
          }

          handleErrorMessage(errorMessage, prevState.errorRef, field);
          if (errorMessage.length > 0) {
            prevState.valid = false;
          } else {
            prevState.valid = true;
          }

          return { ...prevState };
        });
        break;
      case 'securityCode':
        setSecurityCodeState((prevState) => {
          let errorMessage = '';
          const field = prevState.ref.current;
          if (!_.isNil(value)) {
            prevState.dirty = true;
          }

          if (prevState.dirty) {
            if (
              !_.isNil(value?.errorMessages) &&
              value?.errorMessages.length > 0
            ) {
              errorMessage = value.errorMessages[0].message;
            }
          }

          handleErrorMessage(errorMessage, prevState.errorRef, field);
          if (errorMessage.length > 0) {
            prevState.valid = false;
          } else {
            prevState.valid = true;
          }

          return { ...prevState };
        });
        break;
    }
  };

  useEffect(() => {
    if (
      cardNumberState.valid &&
      expirateDateState.valid &&
      securityCodeState.valid &&
      cardholderNameState.valid &&
      cardholderIdentificationNumberState.valid
    ) {
      isValid.current = true;
      cardButtonRef.current?.removeAttribute('disabled');
    } else {
      isValid.current = false;
      cardButtonRef.current?.setAttribute('disabled', '');
    }
  }, [
    cardButtonRef,
    cardNumberState,
    expirateDateState,
    securityCodeState,
    cardholderNameState,
    cardholderIdentificationNumberState,
  ]);

  const clearCardState = () => {
    setCardNumberState((prevState) => ({
      ...prevState,
      dirty: false,
      valid: false,
    }));
    setExpirateDateState((prevState) => ({
      ...prevState,
      dirty: false,
      valid: false,
    }));
    setSecurityCodeState((prevState) => ({
      ...prevState,
      dirty: false,
      valid: false,
    }));
    setCardholderNameState((prevState) => {
      if (prevState.ref.current) prevState.ref.current.value = '';
      return { ...prevState, dirty: false, valid: false, value: '' };
    });
    setCardholderIdentificationNumberState((prevState) => {
      if (prevState.ref.current) prevState.ref.current.value = '';
      return { ...prevState, dirty: false, valid: false, value: '' };
    });
  };

  useEffect(() => {
    clearCardState();
    form.reset();
  }, [paymentMethod]);

  const onSubmit = useCallback(async () => {
    try {
      const body = {
        paymentMethodType: paymentMethod,
      };

      // Processar o pagamento com o MercadoPago

      switch (paymentMethod) {
        case 'debit_card':
        case 'credit_card':
          if (!isValid.current) return;
          setLoading(true);
          // eslint-disable-next-line no-case-declarations
          const identificationNumber =
            cardholderIdentificationNumberRef.current?.value || '';
          // eslint-disable-next-line no-case-declarations
          const cardToken = await createCardToken({
            cardholderName: cardholderNameRef.current?.value || '',
            identificationNumber: identificationNumber || '',
            identificationType: 'CPF',
          });
          _.set(body, 'token', cardToken?.id);
          break;

        case 'bank_transfer':
          setLoading(true);
          _.set(body, 'paymentMethodId', 'pix');
          break;

        case 'ticket':
          if (!form.formState.isValid) return;
          setLoading(true);
          // eslint-disable-next-line no-case-declarations
          const paymentMethodId = paymentMethods.find(
            (method) =>
              method.payment_type_id === 'ticket' &&
              method.id === 'bolbradesco',
          ).id;

          _.set(body, 'paymentMethodId', paymentMethodId);
          _.set(body, 'ticketFirstName', form.getValues().ticketFirstName);
          _.set(body, 'ticketLastName', form.getValues().ticketLastName);
          _.set(
            body,
            'ticketIdentificationNumber',
            form.getValues().ticketIdentificationNumber.replace(/[.-]/g, ''),
          );
          break;

        default:
          break;
      }

      const payment = await paymentService.createPaymentIntent({
        billId: String(bill.id),
        installmentId: installment ? String(installment?.id) : null,
        data: body,
      });

      toast({
        title: 'Oba!',
        variant: 'success',
        description: `Pagamento realizado com sucesso`,
      });
      navigate(`/customer-bills/${customerId}/payment/${payment.id}`);
    } catch {
      toast({
        title: 'Ooops!',
        variant: 'destructive',
        description: `Houve um erro ao efetuar pagamento, tente novamente.`,
      });
    } finally {
      setLoading(false);
    }
  }, [paymentMethod, isValid, paymentMethods]);

  const paymentMethodsToggle = useMemo(() => {
    if (!paymentMethods) return null;

    const hasPix = paymentMethods.find((method) => method.id === 'pix');
    const hasCredit = paymentMethods.filter(
      (method) => method.payment_type_id === 'credit_card',
    );
    const hasDebit = paymentMethods.filter(
      (method) => method.payment_type_id === 'debit_card',
    );
    const hasTicket = paymentMethods.find(
      (method) => method.payment_type_id === 'ticket',
    );

    return (
      <div>
        <label className="mb-4 block">Forma de pagamento</label>
        <ToggleGroup
          type="single"
          className="justify-start flex-col lg:flex-row w-full gap-3 lg:gap-2"
          value={paymentMethod}
          onValueChange={setPaymentMethod}
        >
          {hasDebit.length > 0 && (
            <ToggleGroupItem
              variant="outline"
              value="debit_card"
              aria-label="Débito"
              className="w-full justify-start lg:justify-center lg:w-auto h-[60px] lg:h-12"
            >
              {paymentMethod === 'debit_card' ? (
                <CheckSquare2Icon className="mr-2 h-4 w-4" />
              ) : (
                <Square className="mr-2 h-4 w-4" />
              )}
              Débito
            </ToggleGroupItem>
          )}
          {hasCredit.length > 0 && (
            <ToggleGroupItem
              variant="outline"
              value="credit_card"
              aria-label="Crédito"
              className="w-full justify-start lg:justify-center lg:w-auto h-[60px] lg:h-12"
            >
              {paymentMethod === 'credit_card' ? (
                <CheckSquare2Icon className="mr-2 h-4 w-4" />
              ) : (
                <Square className="mr-2 h-4 w-4" />
              )}
              Crédito
            </ToggleGroupItem>
          )}
          {hasTicket && (
            <ToggleGroupItem
              variant="outline"
              value={hasTicket.payment_type_id}
              aria-label="Boleto"
              className="w-full justify-start lg:justify-center lg:w-auto h-[60px] lg:h-12"
            >
              {paymentMethod === hasTicket.payment_type_id ? (
                <CheckSquare2Icon className="mr-2 h-4 w-4" />
              ) : (
                <Square className="mr-2 h-4 w-4" />
              )}
              Boleto
            </ToggleGroupItem>
          )}
          {hasPix && (
            <ToggleGroupItem
              variant="outline"
              value={hasPix.payment_type_id}
              aria-label="Pix"
              className="w-full justify-start lg:justify-center lg:w-auto h-[60px] lg:h-12"
            >
              {paymentMethod === hasPix.payment_type_id ? (
                <CheckSquare2Icon className="mr-2 h-4 w-4" />
              ) : (
                <Square className="mr-2 h-4 w-4" />
              )}
              Pix
            </ToggleGroupItem>
          )}
        </ToggleGroup>
      </div>
    );
  }, [paymentMethods, paymentMethod]);

  const pixPayment = useMemo(() => {
    if (paymentMethod !== 'bank_transfer') return null;

    return (
      <>
        <h2 className="text-lg">Pagamento PIX</h2>
        <p className="text-muted-foreground text-sm mb-5">
          Ao gerar o pagamento, será gerado um <b>QR code</b> para efetuar o
          pagamento no seu banco.
        </p>
        <Button type="submit" loading={loading} onClick={onSubmit}>
          Pagar
        </Button>
      </>
    );
  }, [paymentMethod, loading]);

  const cardPayment = useMemo(() => {
    if (paymentMethod !== 'credit_card' && paymentMethod !== 'debit_card')
      return null;

    return (
      <div>
        <div className="grid grid-cols-2 gap-4 lg:gap-6">
          <div className="space-y-2 col-span-2">
            <Label>Número do cartão</Label>
            <InputFrame ref={cardNumberRef}>
              <CardNumber
                placeholder="0000 0000 0000 0000"
                onChange={(args) => handleChange('cardNumber', args)}
                onValidityChange={(args) => handleChange('cardNumber', args)}
                style={styles}
              />
            </InputFrame>
            <span ref={errorCardNumber} className="text-red-500 text-sm"></span>
          </div>

          <div className="space-y-2 col-span-1">
            <Label>Data de vencimento</Label>
            <InputFrame ref={cardExpirateDateRef}>
              <ExpirationDate
                placeholder="DD/AAAA"
                onChange={(args) => handleChange('cardExpirationDate', args)}
                onValidityChange={(args) =>
                  handleChange('cardExpirationDate', args)
                }
                style={styles}
              />
            </InputFrame>
            <span
              ref={errorExpirateDate}
              className="text-red-500 text-sm"
            ></span>
          </div>
          <div className="space-y-2">
            <Label>Código de segurança</Label>
            <InputFrame ref={cardSecurityCodeRef}>
              <SecurityCode
                placeholder="000"
                onChange={(args) => handleChange('securityCode', args)}
                onValidityChange={(args) => handleChange('securityCode', args)}
                style={styles}
              />
            </InputFrame>
            <span
              ref={errorSecurityCode}
              className="text-red-500 text-sm"
            ></span>
          </div>

          <div className="space-y-2">
            <Label htmlFor="cardholderName">Nome do titular</Label>
            <Input
              id="cardholderName"
              placeholder="Nome do titular"
              autoComplete="off"
              ref={cardholderNameRef}
              onChange={(ev) => handleChange('cardholderName', ev.target.value)}
            />
            <span
              ref={errorCardholderName}
              className="text-red-500 text-sm"
            ></span>
          </div>
          <div className="space-y-2">
            <Label htmlFor="cardholderIdentificationNumber">
              CPF do titular
            </Label>
            <InputWithMask
              ref={cardholderIdentificationNumberRef}
              id="cardholderIdentificationNumber"
              mask="999.999.999-99"
              maskChar=""
              placeholder="CPF"
              autoComplete="off"
              onChange={(ev) =>
                handleChange('cardholderIdentificationNumber', ev.target.value)
              }
            />
            <span
              ref={errorCardholderIdentificationNumber}
              className="text-red-500 text-sm"
            ></span>
          </div>
        </div>
      </div>
    );
  }, [paymentMethod]);

  const ticketPayment = () => {
    if (paymentMethod !== 'ticket') return null;

    return (
      <>
        <h2 className="text-lg">Pagamento Boleto</h2>
        <p className="text-muted-foreground text-sm ">
          Ao pagar, será gerado um boleto para ser pago em banco ou fintech de
          sua preferencia.
        </p>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="grid grid-cols-2 gap-4 lg:gap-6">
              <FormField
                control={form.control}
                name="ticketFirstName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Nome</FormLabel>
                    <FormControl>
                      <Input {...field} type="text" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="ticketLastName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Sobrenome</FormLabel>
                    <FormControl>
                      <Input {...field} type="text" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="ticketIdentificationNumber"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>CPF</FormLabel>
                    <FormControl>
                      <InputWithMask
                        {...field}
                        autoComplete="false"
                        type="tel"
                        mask="999.999.999-99"
                        alwaysShowMask={false}
                        maskChar={''}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <Button
              type="submit"
              className="w-full mt-5"
              disabled={!form.formState.isValid}
            >
              Pagar
            </Button>
          </form>
        </Form>
      </>
    );
  };

  return (
    <div className="lg:mt-3">
      <div className="grid gap-6">
        {paymentMethodsToggle}
        {cardPayment}
        {ticketPayment()}
        {pixPayment}
        {(paymentMethod === 'debit_card' ||
          paymentMethod === 'credit_card') && (
          <Button
            onClick={onSubmit}
            className="w-full mt-6"
            ref={cardButtonRef}
            loading={loading}
          >
            Pagar
          </Button>
        )}
      </div>
    </div>
  );
};
