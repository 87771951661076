import DataTable from '@/components/data-table';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { File, PlusCircle } from 'lucide-react';
import { Link } from 'react-router-dom';
import columns from './_components/columns';
import { PaginationState } from '@tanstack/react-table';
import useTransaction from './hooks/useTransaction';
import { useCallback, useMemo, useState } from 'react';
import { Transaction } from '@/models/transaction';
import _ from 'lodash';
import { DeleteDialog } from './_components/delete-dialog';

export default function TransactionList() {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [transaction, setTransaction] = useState<Transaction | null>(null);
  const { listPaginated } = useTransaction();
  const { isLoading, data } = listPaginated(
    pagination.pageIndex + 1,
    pagination.pageSize,
  );

  const onDelete = useCallback((transaction: Transaction) => {
    setTransaction(transaction);
  }, []);

  const onCloseDialog = useCallback(() => {
    setTransaction(null);
  }, []);

  const deleteDialog = useMemo(() => {
    if (_.isNil(transaction)) return;

    return <DeleteDialog transaction={transaction} onClose={onCloseDialog} />;
  }, [transaction]);

  return (
    <>
      {deleteDialog}
      <div className="grid auto-rows-max items-start gap-4 md:gap-2 lg:col-span-3">
        <div className="flex items-center">
          <div className="ml-auto flex items-center gap-2">
            <Button size="sm" variant="outline" className="h-8 gap-1">
              <File className="h-3.5 w-3.5" />
              <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                Exportar
              </span>
            </Button>
            <Button size="sm" className="h-8 gap-1">
              <Link
                to={'/transactions/add'}
                className="flex flex-row gap-1 items-center"
              >
                <PlusCircle className="h-3.5 w-3.5" />
                <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                  Adicionar transação
                </span>
              </Link>
            </Button>
          </div>
        </div>
        <Card>
          <CardHeader>
            <CardTitle>Transações</CardTitle>
            <CardDescription>
              Administre todas as transações feitas no sistema.
            </CardDescription>
            <CardContent className="p-0">
              <DataTable
                columns={columns({ onDelete })}
                data={data?.items || []}
                pagination={pagination}
                onPagination={setPagination}
                pageCount={data?.totalPages}
                loading={isLoading}
              />
            </CardContent>
          </CardHeader>
        </Card>
      </div>
    </>
  );
}
