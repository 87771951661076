import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Bar, BarChart, CartesianGrid, XAxis } from 'recharts';

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart';
import { useEffect, useState } from 'react';
import useDashboard from '../hooks/useDashboard';

const chartConfig = {
  expanse: {
    label: 'Gastos',
    color: 'hsl(var(--chart-5))',
  },
  income: {
    label: 'Ganhos',
    color: 'hsl(var(--chart-2))',
  },
} satisfies ChartConfig;

const Overview = () => {
  const { getMonthlyExpanses } = useDashboard();
  const [chartData, setChartData] = useState<any[]>([]);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    const data: any[] = await getMonthlyExpanses.mutateAsync();
    const chartDataCreated = Object.entries(data).map(([key, value]) => {
      const date = parseISO(`${key}-01`); // Adiciona "-01" para criar uma data válida
      return {
        month: format(date, 'MMMM', { locale: ptBR }), // Formata a data para o nome do mês completo
        expanse: value.expanse,
        income: value.income,
      };
    });
    setChartData(chartDataCreated);
  };

  return (
    <Card className="col-span-2">
      <CardHeader>
        <CardTitle className="font-semibold tracking-tight text-md">
          Overview
        </CardTitle>
        <CardDescription>
          Janeiro - Dezembro {new Date().getFullYear()}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer config={chartConfig}>
          <BarChart accessibilityLayer data={chartData}>
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="month"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              tickFormatter={(value) => value.slice(0, 3)} // Exibe os primeiros 3 caracteres do mês
            />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent indicator="dashed" />}
            />
            <Bar dataKey="expanse" fill="var(--color-expanse)" radius={4} />
            <Bar dataKey="income" fill="var(--color-income)" radius={4} />
          </BarChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
};

export default Overview;
