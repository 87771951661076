import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { File, PlusCircle } from 'lucide-react';
import { Link } from 'react-router-dom';
import { columns } from './_components/column';
import { useCallback, useMemo, useState } from 'react';
import DataTable from '@/components/data-table';
import { PaginationState } from '@tanstack/react-table';
import useCategory from '@/hooks/useCategory';
import { Category } from '@/models/category';
import _ from 'lodash';
import { DeleteDialog } from './_components/delete-dialog';

export default function CategoryList() {
  const [category, setCategory] = useState<Category | null>(null);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const { listPaginated } = useCategory();
  const { isLoading, data } = listPaginated();

  const onDelete = useCallback((category: Category) => {
    setCategory(category);
  }, []);

  const onCloseDialog = useCallback(() => {
    setCategory(null);
  }, []);

  const deleteDialog = useMemo(() => {
    if (_.isNil(category)) return;

    return <DeleteDialog category={category} onClose={onCloseDialog} />;
  }, [category]);

  return (
    <>
      {deleteDialog}
      <div className="grid auto-rows-max items-start gap-4 md:gap-2 lg:col-span-3">
        <div className="flex items-center">
          <div className="ml-auto flex items-center gap-2">
            <Button size="sm" variant="outline" className="h-8 gap-1">
              <File className="h-3.5 w-3.5" />
              <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                Exportar
              </span>
            </Button>
            <Button size="sm" className="h-8 gap-1" asChild>
              <Link to={'./add'}>
                <PlusCircle className="h-3.5 w-3.5" />
                <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                  Adicionar categoria
                </span>
              </Link>
            </Button>
          </div>
        </div>
        <Card>
          <CardHeader>
            <CardTitle>Categorias</CardTitle>
            <CardDescription>
              Administre todos as categorias cadastrados no sistema.
            </CardDescription>
          </CardHeader>
          <CardContent className="p-0">
            <DataTable
              columns={columns({ onDelete })}
              data={data || []}
              loading={isLoading}
              pagination={pagination}
              onPagination={setPagination}
              pageCount={data?.totalPages}
            />
          </CardContent>
        </Card>
      </div>
    </>
  );
}
