import { Route, Routes } from 'react-router-dom';
import CustomerList from './customer-list';
import CustomerAddEdit from './customer-add-edit';

export default function Customer() {
  return (
    <Routes>
      <Route id="CustomerList" path="/" element={<CustomerList />} />
      <Route id="CustomerAdd" path="/add" element={<CustomerAddEdit />} />
      <Route
        id="CustomerEdit"
        path="/:customerId"
        element={<CustomerAddEdit />}
      />
    </Routes>
  );
}
