import { useToast } from '@/components/ui/use-toast';
import { transactionService } from '@/lib/services/transaction';
import { useMutation, useQuery, useQueryClient } from 'react-query';

const useTransaction = () => {
  const queryClient = useQueryClient();
  const { toast } = useToast();
  const queryKey = 'transactionPaginatedData';
  const queryStaledTimeKey = 'transactionPaginatedStaledTimeData';

  // Create mutation
  const createMutation = useMutation(transactionService.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      queryClient.invalidateQueries(queryStaledTimeKey);
      toast({
        title: 'Sucesso!',
        variant: 'success',
        description: `Transação criada com sucesso.`,
      });
    },
    onError: () => {
      toast({
        title: 'Ooops!',
        variant: 'destructive',
        description: `Houve um erro ao criar uma transação`,
      });
    },
  });

  const createManyMutation = useMutation(transactionService.createMany, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      queryClient.invalidateQueries(queryStaledTimeKey);
      toast({
        title: 'Sucesso!',
        variant: 'success',
        description: `Transações criadas com sucesso.`,
      });
    },
    onError: () => {
      toast({
        title: 'Ooops!',
        variant: 'destructive',
        description: `Houve um erro ao criar transações`,
      });
    },
  });

  // Read mutation
  const readMutation = useMutation(transactionService.read, {
    onError: () => {
      toast({
        title: 'Ooops!',
        description: `Houve um erro ao criar um cliente.`,
      });
    },
  });

  // List query
  const listPaginated = (
    page: number,
    pageSize: number,
    sortBy?: string,
    sortOrder?: 'desc' | 'asc',
  ) => {
    return useQuery(
      [queryKey, page, pageSize],
      () => transactionService.list({ page, pageSize, sortBy, sortOrder }),
      {
        keepPreviousData: true,
        select: (data) => ({
          items: data.items || [],
          totalPages: data.totalPages || 0,
          pageIndex: page,
          totalItems: data.totalItems,
        }),
      },
    );
  };

  const listStaledTimePaginated = (
    page: number,
    pageSize: number,
    sortBy?: string,
    sortOrder?: 'desc' | 'asc',
  ) => {
    return useQuery(
      [queryKey, page, pageSize],
      () => transactionService.list({ page, pageSize, sortBy, sortOrder }),
      {
        keepPreviousData: true,
        select: (data) => ({
          items: data.items || [],
          totalPages: data.totalPages || 0,
          pageIndex: page,
          totalItems: data.totalItems,
        }),
      },
    );
  };

  // Update mutation
  const updateMutation = useMutation(
    ({ id, data }: { id: string; data: any }) =>
      transactionService.update(id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
        queryClient.invalidateQueries(queryStaledTimeKey);
      },
    },
  );

  // Delete mutation
  const deleteMutation = useMutation(transactionService.delete, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      queryClient.invalidateQueries(queryStaledTimeKey);
      toast({
        title: 'Sucesso!',
        variant: 'success',
        description: `Transação atualizado com sucesso.`,
      });
    },
    onError: () => {
      toast({
        title: 'Ooops!',
        variant: 'destructive',
        description: `Houve um erro ao atualizar transação.`,
      });
    },
  });

  return {
    createMutation,
    readMutation,
    listPaginated,
    listStaledTimePaginated,
    createManyMutation,
    updateMutation,
    deleteMutation,
  };
};

export default useTransaction;
