import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useCustomerBill from './hooks/useCustomerBill';
import { useEffect, useMemo, useState } from 'react';
import Loader from '@/components/loader';
import { Installment } from '@/models/installment';
import usePayment from '@/hooks/usePayment';
import { PaymentCheckout } from './_components/payment';
import { formatCurrency } from '@/lib/utils';

export const CustomerCheckout = () => {
  const { customerId, billId, installmentIndex } = useParams();
  const { readCustomerBillMutation } = useCustomerBill();
  const { paymentMethodsMutation } = usePayment();
  const [loading, setLoading] = useState(true);
  const nagivate = useNavigate();

  const [installment, setInstallment] = useState<Installment>();
  const [paymentMethods, setPaymentMethods] = useState<any>();

  useEffect(() => {
    if (customerId && billId) {
      fetchPayment();
    }
  }, [customerId, billId]);

  useEffect(() => {
    // Validate if bill or installment is already paid
    if (readCustomerBillMutation.data) {
      if (
        readCustomerBillMutation.data?.isPaid ||
        (readCustomerBillMutation.data?.installment &&
          readCustomerBillMutation.data?.installment?.find(
            (installment: any) =>
              installment.installmentIndex === Number(installmentIndex),
          )?.isPaid)
      ) {
        nagivate(`/customer-bills/${customerId}`);
      }
    }
  }, [readCustomerBillMutation]);

  const fetchPayment = async () => {
    if (customerId && billId) {
      setLoading(true);
      try {
        const data = await Promise.all([
          readCustomerBillMutation.mutateAsync({ customerId, billId }),
          paymentMethodsMutation.mutateAsync(),
        ]);

        if (data[0] && installmentIndex) {
          setInstallment(
            data[0].installment.find(
              (i: any) => i.installmentIndex == installmentIndex,
            ),
          );
        }
        setPaymentMethods(data[1]);
      } finally {
        setLoading(false);
      }
    }
  };

  const renderPaymentMethods = useMemo(() => {
    if (!paymentMethods) return null;

    const groupedMethods = paymentMethods.reduce((acc: any, method: any) => {
      const category =
        method.payment_type_id === 'debit_card'
          ? 'Débito'
          : method.payment_type_id === 'credit_card'
            ? 'Crédito'
            : method.payment_type_id === 'bank_transfer'
              ? 'PIX'
              : 'Outros';

      let categoryGroup = acc.find((group: any) => group.category === category);
      if (!categoryGroup) {
        categoryGroup = { category, methods: [] };
        acc.push(categoryGroup);
      }

      categoryGroup.methods.push({
        name: method.name,
        thumbnail: method.thumbnail,
        secureThumbnail: method.secure_thumbnail,
      });

      return acc;
    }, []);

    return (
      <div className="grid grid-col-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
        {groupedMethods.map((category: any, key: number) => (
          <div className="flex flex-col gap-2" key={key}>
            <span className="font-semibold text-xs text-muted-foreground">
              {category.category}
            </span>
            <div className="flex flex-row gap-2">
              {category.methods.map((method: any, index: number) => (
                <img
                  key={'img-' + index}
                  src={method.secureThumbnail}
                  className="w-auto h-5"
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }, [paymentMethods]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row gap-3 items-center">
        <Button variant="outline" className="h-10" asChild>
          <Link to={`/customer-bills/${customerId}`}>
            <ChevronLeft className="w-4 h-4" />
          </Link>
        </Button>
        <div className="font-semibold text-md flex-row flex items-center gap-1">
          <span>Conta #{billId}</span>
          {installmentIndex ? (
            <span className="flex flex-row items-center gap-1">
              <ChevronRight className="w-4 h-4 text-muted-foreground" /> Parcela{' '}
              {installmentIndex}x
            </span>
          ) : null}
        </div>
      </div>
      <div className="mx-auto grid w-full max-w-6xl items-start gap-6 md:grid-cols-[250px_1fr] lg:grid-cols-[350px_1fr]">
        {loading ? (
          <Loader />
        ) : (
          <>
            <Card>
              <CardHeader>
                <CardTitle className="text-lg">Detalhes</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="flex flex-col gap-3">
                  <div className="flex flex-row justify-between gap-3">
                    <span className="text-sm ">Quantia</span>
                    <span className="text-sm text-muted-foreground">
                      {formatCurrency(readCustomerBillMutation.data?.amount)}
                    </span>
                  </div>
                  <div className="flex flex-row justify-between gap-3">
                    <span className="text-sm">Juros</span>
                    <span className="text-sm text-muted-foreground">
                      {readCustomerBillMutation.data?.interestAmount}%
                    </span>
                  </div>
                  <div className="flex flex-row justify-between gap-3">
                    <span className="text-sm">Multa</span>
                    <span className="text-sm text-muted-foreground">
                      {readCustomerBillMutation.data?.lateFeeType ===
                      'PERCENTAGE'
                        ? `${readCustomerBillMutation.data?.lateFeeAmount}%`
                        : formatCurrency(
                            readCustomerBillMutation.data?.lateFeeAmount,
                          )}
                    </span>
                  </div>
                  <div className="flex flex-row justify-between gap-3">
                    <span className="text-sm">Valor total</span>
                    <span className="text-sm text-muted-foreground">
                      {formatCurrency(
                        readCustomerBillMutation.data?.totalAmount,
                      )}
                    </span>
                  </div>
                  {installmentIndex && (
                    <div className="flex flex-row justify-between gap-3">
                      <span className="text-sm ">
                        Valor da parcela ({installmentIndex}x)
                      </span>
                      <span className="text-sm text-muted-foreground">
                        {formatCurrency(installment?.totalAmount as number)}
                      </span>
                    </div>
                  )}
                  <hr />
                  <div className="flex flex-row justify-between gap-3">
                    <span className="text-sm font-semibold ">
                      Valor a pagar
                    </span>
                    <span className="text-sm font-semibold">
                      {installmentIndex
                        ? formatCurrency(installment?.totalAmount as number)
                        : formatCurrency(
                            readCustomerBillMutation.data?.totalAmount,
                          )}
                    </span>
                  </div>
                </div>
              </CardContent>
            </Card>
            <div>
              <Card>
                <CardHeader>
                  <CardTitle className="text-lg">Pagamento</CardTitle>
                </CardHeader>
                <CardContent>
                  <PaymentCheckout
                    bill={readCustomerBillMutation.data}
                    installment={installment}
                    paymentMethods={paymentMethods}
                  />
                </CardContent>
              </Card>
              {renderPaymentMethods}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
