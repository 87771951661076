import api from '../api';

const API_URL = `/dashboard`;

export const dashboardService = {
  totalAmounts: async ({
    endDate,
    startDate,
  }: {
    startDate: string;
    endDate: string;
  }) => {
    const response = await api.get(`${API_URL}/total`, {
      params: { startDate, endDate },
    });
    return response.data;
  },
  consumByCategory: async (startDate: string, endDate: string) => {
    const response = await api.get(`${API_URL}/category`, {
      params: { startDate, endDate },
    });
    return response.data;
  },
  monthlyExpanses: async () => {
    const response = await api.get(`${API_URL}/monthly-expanses`);
    return response.data;
  },
  getUpcomingBills: async () => {
    const response = await api.get(`${API_URL}/upcoming-bills`);
    return response.data;
  },
};
