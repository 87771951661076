import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import { FormControl } from '@/components/ui/form';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import useCustomer from '@/modules/customer/hooks/useCustomer';
import { Check, ChevronDown, Loader2 } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';

type CustomerComboboxProps = {
  value: string;
  onChange: (value: string) => void;
};

export const CustomerCombobox = ({
  value,
  onChange,
}: CustomerComboboxProps) => {
  const { listPaginated } = useCustomer();
  const [page] = useState(1);
  const { data, isLoading } = listPaginated(page, 10);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [customers, setCustomers] = useState<
    { label: string; value: string }[]
  >([]);
  useEffect(() => {
    if (data && data.items.length > 0) {
      const mapped = data.items.map((customer: any) => ({
        label: customer.name,
        value: String(customer.id),
      }));
      setCustomers(mapped);
    }
  }, [data]);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <FormControl>
          <Button
            variant="outline"
            role="combobox"
            disabled={isLoading}
            className={cn(
              'justify-between w-full',
              !value && 'text-muted-foreground',
            )}
            ref={buttonRef}
          >
            {value
              ? customers.find((account) => account.value === value)?.label
              : 'Selecione uma conta'}

            {isLoading ? (
              <Loader2 className="w-5 h-5 animate-spin" />
            ) : (
              <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            )}
          </Button>
        </FormControl>
      </PopoverTrigger>
      <PopoverContent
        className={cn('p-0')}
        style={{ width: buttonRef.current?.clientWidth }}
      >
        <Command>
          <CommandInput placeholder="Pesquisar um cliente..." />
          <CommandList>
            <CommandEmpty>Nenhuma cliente.</CommandEmpty>
            <CommandGroup>
              {customers.map((customer) => {
                return (
                  <CommandItem
                    value={customer.value}
                    key={customer.value}
                    onSelect={() => {
                      onChange(customer.value);
                    }}
                  >
                    <Check
                      className={cn(
                        'mr-2 h-4 w-4',
                        customer.value === value ? 'opacity-100' : 'opacity-0',
                      )}
                    />
                    {customer.label}
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
