import { AccountCombobox } from '@/components/account-combobox';
import { CategoryCombobox } from '@/components/category-combobox';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { cn } from '@/lib/utils';
import { TransactionTypeEnum } from '@/models/enums/transaction-type.enum';
import { format } from 'date-fns';
import { CalendarIcon, Trash2 } from 'lucide-react';
import { Control, UseFormReturn } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { TransactionSchema } from '../transaction-edit-add';

export const TransactionForm = ({
  control,
  index,
  onRemove,
}: {
  control: Control<
    {
      transactions: TransactionSchema[];
    },
    any
  >;
  index: number;
  form: UseFormReturn<any>;
  onRemove: (index: number) => void;
}) => {
  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between px-4 py-2">
        <CardTitle className="text-md">Transação {index + 1}</CardTitle>
        {index > 0 && (
          <Button
            variant={'destructive'}
            className="rounded-full w-10 h-10 p-0"
            onClick={() => onRemove?.(index)}
          >
            <Trash2 className="w-4 h-4" />
          </Button>
        )}
      </CardHeader>
      <CardContent>
        <div className="grid lg:grid-cols-3 gap-3">
          <FormField
            name={`transactions.${index}.type`}
            control={control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Tipo</FormLabel>
                <FormControl>
                  <Select
                    value={field.value || ''}
                    onValueChange={field.onChange}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Selecione um tipo" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem value={TransactionTypeEnum.INCOME}>
                          Ganho
                        </SelectItem>
                        <SelectItem value={TransactionTypeEnum.EXPANSE}>
                          Gasto
                        </SelectItem>
                        <SelectItem value={TransactionTypeEnum.TRANSFER}>
                          Transferência
                        </SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={control}
            name={`transactions.${index}.amount`}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Quantia</FormLabel>
                <FormControl>
                  <NumericFormat
                    value={field.value}
                    onValueChange={(values) =>
                      field.onChange(values.floatValue)
                    }
                    customInput={Input}
                    prefix="R$ "
                    allowNegative={true}
                    thousandSeparator="."
                    decimalSeparator=","
                    fixedDecimalScale={true}
                    decimalScale={2}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="space-y-2">
            <FormLabel>Data</FormLabel>
            <div className="flex flex-row gap-2">
              <FormField
                control={control}
                name={`transactions.${index}.date`}
                render={({ field }) => (
                  <FormItem className="w-full flex-1">
                    <Popover>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant={'outline'}
                            className={cn(
                              'pl-3 text-left font-normal w-full',
                              !field.value && 'text-muted-foreground',
                            )}
                          >
                            {field.value ? (
                              format(field.value, 'dd/MM/yyyy')
                            ) : (
                              <span>Selecione uma data</span>
                            )}
                            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                          mode="single"
                          selected={field.value}
                          onSelect={field.onChange}
                          disabled={(date) =>
                            date > new Date() || date < new Date('1900-01-01')
                          }
                          initialFocus
                        />
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={control}
                name={`transactions.${index}.date`}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        onChange={(ev) => {
                          const [hour, minutes] = ev.target.value.split(':');
                          if (field.value) {
                            const value = field.value as Date;
                            value.setHours(+hour);
                            value.setMinutes(+minutes);
                            field.onChange(value);
                          }
                        }}
                        value={
                          field?.value ? format(field?.value, 'HH:mm') : ''
                        }
                        type="time"
                        className="w-[100px]"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
          <FormField
            control={control}
            name={`transactions.${index}.categoryId`}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Categoria</FormLabel>
                <FormControl>
                  <CategoryCombobox
                    onChange={field.onChange}
                    value={field.value}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name={`transactions.${index}.accountId`}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Conta</FormLabel>
                <FormControl>
                  <AccountCombobox
                    onChange={field.onChange}
                    value={field.value}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name={`transactions.${index}.description`}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Descrição</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </CardContent>
    </Card>
  );
};
