import { useToast } from '@/components/ui/use-toast';
import { paymentService } from '@/lib/services/payment';
import { useMutation } from 'react-query';

const usePayment = () => {
  const { toast } = useToast();

  // Create Mutation
  const paymentMethodsMutation = useMutation(paymentService.paymentMethods, {
    mutationKey: 'PaymentMethodsMutate',
    onError: () => {
      toast({
        title: 'Ooops!',
        variant: 'destructive',
        description: `Houve um erro ao recuperar metodos de pagamento`,
      });
    },
  });

  return {
    paymentMethodsMutation,
  };
};

export default usePayment;
