import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';

import { cn, formatCurrency } from '@/lib/utils';
import { Installment } from '@/models/installment';
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@radix-ui/react-accordion';
import { format } from 'date-fns';
import { ChevronDown, DollarSign } from 'lucide-react';
import { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';

export const BillInstallmentDetail = ({
  installment,
}: {
  installment: Installment;
}) => {
  const { customerId } = useParams();
  const statusBadge = useMemo(() => {
    const isPaid = installment.isPaid;
    const dueDate = installment.dueDate;
    const isDue = new Date().getTime() > new Date(dueDate).getTime();
    const label = isPaid ? 'Pago' : isDue ? 'Vencido' : 'Pendente';

    return (
      <Badge
        variant="outline"
        className={cn(
          isPaid
            ? 'bg-green-500/30'
            : isDue
              ? 'bg-red-500/30'
              : 'bg-orange-500/30',
          'border-none',
        )}
      >
        {label}
      </Badge>
    );
  }, []);

  return (
    <AccordionItem
      value={'value-' + installment.installmentIndex}
      className="bg-muted/40 rounded-md border"
    >
      <AccordionTrigger disabled={installment.isPaid} className="w-full">
        <div className="bg-secondary h-10 border rounded-md flex flex-row items-center px-4 justify-between cursor-pointer">
          <div className=" flex flex-row gap-2 w-full">
            {statusBadge}
            <span className="font-semibold text-sm">
              {installment.installmentIndex}x
            </span>
            <span className="font-semibold text-sm">
              {formatCurrency(installment.totalAmount)}
            </span>
          </div>
          <div className="flex-row flex gap-3 items-center">
            {!installment.isPaid && (
              <>
                <span className="text-sm text-muted-foreground">
                  {format(installment.dueDate, 'dd/MM/yyyy')}
                </span>{' '}
                <ChevronDown className="w-5 h-5 text-muted-foreground" />
              </>
            )}
            {installment.isPaid && installment?.paidAt && (
              <span className="text-sm truncate text-muted-foreground">
                Pago em {format(new Date(installment?.paidAt), 'dd/MM/yyyy')}
              </span>
            )}
          </div>
        </div>
      </AccordionTrigger>
      <AccordionContent className="p-4 text-end">
        <Button variant="default" className="h-7">
          <Link
            to={`/customer-bills/${customerId}/bill/${installment.billId}/installment/${installment.installmentIndex}/checkout`}
            className="flex flex-row"
          >
            <DollarSign className="w-4 h-4 mr-2" />
            <span>Pagar</span>
          </Link>
        </Button>
      </AccordionContent>
    </AccordionItem>
  );
};
