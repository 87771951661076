import DataTable from '@/components/data-table';
import { PaginationState } from '@tanstack/react-table';
import { installmentsColumns } from './columns';
import { useCallback, useMemo, useState } from 'react';
import useBill from '../hooks/useBill';
import { Installment } from '@/models/installment';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogCancel,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Checkbox } from '@/components/ui/checkbox';
import { CheckedState } from '@radix-ui/react-checkbox';
import { Button } from '@/components/ui/button';
import _ from 'lodash';

export default function InstallmentsList({ billId }: { billId: string }) {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const { listInstallmentsPaginated, payInstallmentMutation } = useBill();
  const { isLoading, data } = listInstallmentsPaginated(
    billId,
    pagination.pageIndex + 1,
    pagination.pageSize,
  );
  const [open, setOpen] = useState(false);
  const [installment, setInstallment] = useState<Installment | null>(null);
  const [registerTransaction, setRegisterTransaction] =
    useState<CheckedState>();

  const onPay = useCallback(
    (installment: Installment) => {
      if (!installment) return;

      setOpen(true);
      setInstallment(installment);
    },
    [setOpen, setInstallment],
  );

  const payInstallment = useCallback(async () => {
    if (!installment) return;
    try {
      const data = { payAt: new Date().toISOString() };
      if (registerTransaction) {
        _.set(data, 'registerTransaction', registerTransaction);
      }

      await payInstallmentMutation.mutateAsync({
        id: String(installment.id),
        billId: String(installment.billId),
        data,
      });
      setRegisterTransaction(false);
      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  }, [registerTransaction, installment]);

  const alertDialog = useMemo(() => {
    return (
      <AlertDialog open={open} onOpenChange={setOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Você tem certeza?</AlertDialogTitle>
            <AlertDialogDescription>
              Ao finalizar o pagamento, você não poderá reverter. Deseja
              continuar ?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <div className="flex flex-row gap-2 items-center justify-start h-full w-full">
            <Checkbox
              id="terms"
              checked={registerTransaction}
              onCheckedChange={setRegisterTransaction}
            />
            <label
              htmlFor="terms"
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              Registrar transação?
            </label>
          </div>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancelar</AlertDialogCancel>
            <AlertDialogAction asChild>
              <Button
                className="flex"
                type="button"
                onClick={payInstallment}
                loading={payInstallmentMutation.isLoading}
              >
                Continuar
              </Button>
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    );
  }, [open, setOpen, registerTransaction, setRegisterTransaction]);

  return (
    <>
      {alertDialog}
      <DataTable
        columns={installmentsColumns({ onPay })}
        loading={isLoading}
        data={data?.items || []}
        pagination={pagination}
        onPagination={setPagination}
        pageCount={data?.totalPages || 1}
      />
    </>
  );
}
