import axios from 'axios';

const api = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem('user-jwt') || `{}`);
    if (token?.access_token) {
      config.headers.Authorization = `Bearer ${token?.access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Interceptores de resposta
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Go to login
    }
    return Promise.reject(error);
  },
);

export default api;
