import { Button } from '@/components/ui/button';
import { CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import useTransaction from './hooks/useTransaction';
import { TransactionForm } from './_components/transaction-form';
import { Form } from '@/components/ui/form';
import { Plus } from 'lucide-react';

export interface TransactionSchema {
  description: string;
  amount: number;
  date: Date;
  type: string;
  categoryId: string;
  accountId: string;
}

const formSchema = z.object({
  transactions: z.array(
    z.object({
      description: z.string().min(1, 'A descrição é um campo obrigatório'),
      amount: z.number().refine(
        (value) => {
          const numValue = Number(value);
          return !isNaN(numValue) && numValue >= 1 && numValue <= 9999999;
        },
        {
          message: 'O valor deve ser um número válido entre 1 e 9.999.999',
        },
      ),
      date: z.date({
        required_error: 'A data e um campo obrigatório',
      }),
      type: z.string().min(1, 'O tipo é um campo obrigatório'),
      categoryId: z.string().min(1),
      accountId: z.string().min(1),
    }),
  ),
});

export default function TransactionAddEdit() {
  const { transactionId } = useParams();
  const navigate = useNavigate();
  const { createMutation, createManyMutation, readMutation, updateMutation } = useTransaction();
  const [isOnce, setIsOnce] = useState(false);

  const form = useForm<{ transactions: TransactionSchema[] }>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      transactions: [
        {
          description: '',
          amount: 0,
          date: new Date(),
          type: '',
          categoryId: '',
          accountId: '',
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    rules: {
      minLength: 1,
    },
    name: 'transactions',
  });

  useEffect(() => {
    fechTransaction();
  }, [transactionId]);

  const fechTransaction = async () => {
    if (transactionId) {
      setIsOnce(true);
      const data = await readMutation.mutateAsync(transactionId);
      form.reset({
        transactions: [
          {
            ...data,
            categoryId: String(data.categoryId),
            accountId: String(data.accountId),
            amount: Number(data.amount),
            date: new Date(data.date),
          },
        ],
      });
    }
  };

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      if(!transactionId) {
        if(values.transactions.length > 1) {
          const data = [...values.transactions.map((value) => ({...value, amount: Number(value.amount)}))];
          await createManyMutation.mutateAsync(data);
        } else {
          const data = {...values.transactions[0], amount: Number(values.transactions[0].amount)};
          await createMutation.mutateAsync(data);
        } 
      } else {
        const data = {...values.transactions[0], amount: Number(values.transactions[0].amount)};
        await updateMutation.mutateAsync({
          id: transactionId,
          data,
        });
      }
      navigate('/transactions');
    } catch (error) {
      console.log('error');
    }
  };

  const appendTransaction = () =>
    append({
      description: '',
      date: new Date(),
      accountId: '',
      amount: 0,
      categoryId: '',
      type: '',
    });

  const onRemove = (index: number) => remove(index);

  const renderHeader = useMemo(() => {
    return (
      <CardHeader className="px-2 py-1">
        <CardTitle>
          {!transactionId ? 'Adicionar transação' : 'Editar transação'}
        </CardTitle>
        {!transactionId && (
          <CardDescription>
            Insira todos os dados abaixo para cadastrar uma nova transação.
          </CardDescription>
        )}
      </CardHeader>
    );
  }, [transactionId]);
  return (
    <>
      <div className="col-span-3">{renderHeader}</div>
      <div className="grid auto-rows-max items-start gap-4 md:gap-2 lg:col-span-3 relative">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">
            {fields.map((field, index) => (
              <TransactionForm
                control={form.control}
                form={form}
                index={index}
                key={field.id}
                onRemove={onRemove}
              />
            ))}
            {!isOnce && (
              <Button
                variant="outline"
                type="button"
                className="border-dashed cursor-pointer h-14 w-full"
                onClick={appendTransaction}
              >
                <Plus className="w-4 h-4 mr-1" />
                <span>Adicionar transação</span>
              </Button>
            )}
            <div className="flex flex-row gap-2 items-center justify-end sticky bottom-0 h-30 py-4 ">
              {(!createMutation.isLoading || !createManyMutation.isLoading || !updateMutation.isLoading) && (
                <Button type="button" variant="outline" asChild>
                  <Link to={'/transactions'}>Cancelar</Link>
                </Button>
              )}
              <Button
                type="submit"
                loading={createMutation.isLoading || createManyMutation.isLoading || updateMutation.isLoading}
              >
                Salvar
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </>
  );
}
