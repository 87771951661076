import { useToast } from '@/components/ui/use-toast';
import { customerService } from '@/lib/services/customer';
import { useMutation, useQuery, useQueryClient } from 'react-query';

const useCustomer = () => {
  const queryClient = useQueryClient();
  const { toast } = useToast();
  const queryKey = 'customerPaginatedData';

  // Create Mutation
  const createMutation = useMutation(customerService.create, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(queryKey);
      toast({
        title: 'Sucesso!',
        variant: 'success',
        description: `Cliente ${data.name} criado com sucesso.`,
      });
    },
    onError: () => {
      toast({
        title: 'Ooops!',
        variant: 'destructive',
        description: `Houve um erro ao criar um cliente.`,
      });
    },
  });

  // Read mutation
  const readMutation = useMutation(customerService.read, {
    onError: () => {
      toast({
        title: 'Ooops!',
        variant: 'destructive',
        description: `Houve um erro ao criar um cliente.`,
      });
    },
  });

  // List query
  const listPaginated = (page: number, pageSize: number) => {
    return useQuery(
      [queryKey, page, pageSize],
      () => customerService.list({ page, pageSize }),
      {
        keepPreviousData: true,
        staleTime: Infinity,
        select: (data) => ({
          items: data.items || [],
          totalPages: data.totalPages || 0,
          pageIndex: page,
          totalItems: data.totalItems,
        }),
      },
    );
  };

  // Update Mutation
  const updateMutation = useMutation(
    ({ id, data }: { id: string; data: any }) =>
      customerService.update(id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
    },
  );

  // Delete Mutation
  const deleteMutation = useMutation(customerService.delete, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      toast({
        title: 'Sucesso!',
        variant: 'success',
        description: `Cliente deletado com sucesso.`,
      });
    },
    onError: () => {
      toast({
        title: 'Ooops!',
        variant: 'destructive',
        description: `Houve um erro ao deletar um cliente.`,
      });
    },
  });

  return {
    createMutation,
    readMutation,
    listPaginated,
    updateMutation,
    deleteMutation,
  };
};

export default useCustomer;
