import { ChevronDown, ChevronUp } from 'lucide-react';

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { useState } from 'react';
import { ColumnDef, PaginationState } from '@tanstack/react-table';
import useTransaction from '@/modules/transaction/hooks/useTransaction';
import EmptyTableValue from '@/components/empty-table-value';
import { BadgeCategory } from '@/components/ui/badge';
import { format } from 'date-fns';
import { TransactionTypeEnum } from '@/models/enums/transaction-type.enum';
import { Transaction } from '@/models/transaction';
import DataTable from '@/components/data-table';
import { formatCurrency } from '@/lib/utils';

export function LastTransactions() {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 5,
  });
  const { listStaledTimePaginated } = useTransaction();
  const { isLoading, data } = listStaledTimePaginated(
    pagination.pageIndex + 1,
    pagination.pageSize,
  );

  const columns: ColumnDef<Transaction>[] = [
    {
      accessorKey: 'description',
      header: 'Descrição',
    },
    {
      accessorKey: 'amount',
      header: 'Valor',
      cell: ({ row }) => {
        const data = row.original;
        const amount = formatCurrency(data.amount);
        return (
          <div className="flex flex-row gap-1">
            {data.type === TransactionTypeEnum.EXPANSE && (
              <ChevronDown className="w-4 h-5 text-red-600" />
            )}
            {data.type === TransactionTypeEnum.INCOME && (
              <ChevronUp className="w-4 h-5 text-green-600" />
            )}
            <span>{amount}</span>
          </div>
        );
      },
    },
    {
      accessorKey: 'category',
      header: 'Categoria',
      cell: ({ row }) => {
        const category = row.original.category;

        return (
          <BadgeCategory color={category.color} variant="secondary">
            {category.name}
          </BadgeCategory>
        );
      },
    },
    {
      accessorKey: 'date',
      header: 'Data',
      cell: ({ row }) => {
        const data = row.original;

        return format(new Date(data.date), 'dd/MM/yyyy HH:mm');
      },
    },
    {
      accessorKey: 'bill',
      header: 'Conta',
      cell: ({ row }) => {
        const data = row.original;
        if (!data.bill) return <EmptyTableValue />;

        return data.bill.id;
      },
    },
    {
      accessorKey: 'customer',
      header: 'Cliente',
      cell: ({ row }) => {
        const data = row.original;
        if (!data.customer) return <EmptyTableValue />;

        return data.customer.name;
      },
    },
  ];

  return (
    <Card className="h-full">
      <CardHeader>
        <CardTitle className="font-semibold tracking-tight text-md">
          Últimas transferências
        </CardTitle>
        <CardDescription>Últimas 5 transferências feitas</CardDescription>
      </CardHeader>
      <CardContent>
        <DataTable
          columns={columns}
          data={data?.items || []}
          pagination={pagination}
          onPagination={setPagination}
          pageCount={data?.totalPages}
          loading={isLoading}
        />
      </CardContent>
    </Card>
  );
}
