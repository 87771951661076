import EmptyTableValue from '@/components/empty-table-value';
import { BadgeCategory } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Category } from '@/models/category';
import { ColumnDef } from '@tanstack/react-table';
import { Edit, MoreHorizontal, Trash2 } from 'lucide-react';
import { Link } from 'react-router-dom';

export const columns = ({
  onDelete,
}: {
  onDelete: (category: Category) => void;
}) => {
  const columns: ColumnDef<Category>[] = [
    {
      accessorKey: 'id',
      header: 'ID',
    },
    {
      accessorKey: 'name',
      header: 'Nome',
      cell: ({ row }) => {
        const category = row.original;

        return (
          <BadgeCategory color={category.color} variant="outline">
            {category.name}
          </BadgeCategory>
        );
      },
    },
    {
      accessorKey: 'description',
      header: 'Descrição',
      cell: ({ row }) => {
        const category = row.original;

        if (!category.description) return <EmptyTableValue />;

        return category.description;
      },
    },
    {
      id: 'actions',
      cell: ({ row }) => {
        const category = row.original;

        return (
          <div className="text-end">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <span className="sr-only">Open menu</span>
                  <MoreHorizontal className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>Ações</DropdownMenuLabel>
                <DropdownMenuItem
                  onClick={() =>
                    navigator.clipboard.writeText(category.id.toString())
                  }
                >
                  Copiar ID
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem asChild>
                  <Link
                    to={`/categories/${category.id}`}
                    className="flex gap-2"
                  >
                    <Edit className="w-4 h-4" />
                    <span>Editar categoria</span>
                  </Link>
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => onDelete?.(row.original)}
                  className="flex gap-2"
                >
                  <Trash2 className="w-4 h-4" />
                  <span>Deletar</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    },
  ];

  return columns;
};
