import { useParams } from 'react-router-dom';
import useCustomerBill from './hooks/useCustomerBill';
import Loader from '@/components/loader';
import { useMemo } from 'react';
import { CheckCircle, Copy, Download } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { useToast } from '@/components/ui/use-toast';
import { formatCurrency } from '@/lib/utils';
import PaymentStatus from './_components/payment-status';
import _ from 'lodash';

export const Payment = () => {
  const { paymentId } = useParams();
  const { readPayment } = useCustomerBill();
  const { data, isLoading } = readPayment(paymentId as string);
  const { toast } = useToast();

  const copyText = (text: string) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(
        () =>
          toast({
            title: 'Copiado',
            description: `QR code para a área de transferência`,
          }),
        () =>
          toast({
            title: 'Oops!',
            variant: 'destructive',
            description: `Não foi possivel copiar QR code`,
          }),
      );
    } else {
      // Fallback para navegadores que não suportam o Clipboard API
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        toast({
          title: 'Copiado',
          description: `QR code para a área de transferência`,
        });
      } catch {
        toast({
          title: 'Oops!',
          variant: 'destructive',
          description: `Não foi possivel copiar QR code`,
        });
      }
      document.body.removeChild(textArea);
    }
  };

  const pixType = useMemo(() => {
    if (data?.method === 'PIX' && data?.status === 'PENDING')
      return (
        <>
          <div className="text-center justify-center items-center flex flex-col gap-4 text-muted-foreground">
            <p>
              Realize o pagamento do <b className="text-white">QR code</b>{' '}
              abaixo para concluir o pagamento:
            </p>
            <div className="h-60 w-60 bg-secondary border-muted-foreground rounded overflow-hidden">
              <img
                src={`data:image/png;base64,${data?.qrCodeBase64}`}
                className="w-60 h-60"
              />
            </div>
          </div>
          <div className="space-y-2 text-center mt-5">
            <Label>Código QR code</Label>
            <div className="mx-auto max-w-[350px] flex flex-row items-center gap-2 mt-4">
              <Input type="text" value={data?.qrCode} readOnly />
              <Button variant="outline" onClick={() => copyText(data?.qrCode)}>
                <Copy className="w-5 h-5" />
              </Button>
            </div>
          </div>
        </>
      );
    else return null;
  }, [data, copyText]);

  const ticketType = useMemo(() => {
    if (data?.method === 'TICKET' && data?.status === 'PENDING')
      return (
        <>
          <div className="text-center justify-center items-center flex flex-col gap-4 text-muted-foreground">
            <p>
              Realize o pagamento do <b className="text-white">boleto</b> abaixo
              para concluir o pagamento:
            </p>
            <div className="h-[600px] w-[100%] bg-secondary border-muted-foreground rounded overflow-hidden">
              <embed
                width="100%"
                height="100%"
                src="https://eamena.org/sites/default/files/eamena/documents/media/egypt_panel_01_small.pdf"
              />
            </div>
          </div>
          <div className="space-y-2 text-center mt-5">
            <Button variant="outline" onClick={() => copyText(data?.qrCode)}>
              <Download className="w-5 h-5 mr-2" />
              Baixar boleto
            </Button>
          </div>
        </>
      );
    else return null;
  }, [data]);

  const renderPending = useMemo(() => {
    if (
      data?.status === 'PENDING' &&
      (data.method == 'CREDIT_CARD' || data.method == 'DEBIT_CARD')
    )
      return (
        <div>
          O status atual desta transação é Pendente. Isso significa que o
          processamento ou a confirmação do débito/crédito ainda não foi
          concluído. Acompanhe o status para obter atualizações e verificar
          quando a transação for finalizada. Se você tiver dúvidas ou precisar
          de mais informações, entre em contato com o suporte.
        </div>
      );
    return null;
  }, [data]);

  const renderComplete = useMemo(() => {
    if (data?.status !== 'APPROVED') return null;
    return (
      <div>
        Seu pagamento foi processado com sucesso. Agradecemos pelo seu
        pagamento. Você receberá um e-mail de confirmação em breve.
      </div>
    );
  }, [data]);

  return (
    <>
      <div className="flex flex-col gap-2 justify-center items-center py-4">
        <CheckCircle className="w-8 h-8 text-green-600" />
        <h1 className="text-2xl font-semibold text-green-600">
          Pagamento realizado
        </h1>
      </div>
      <div className="space-y-3">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <PaymentStatus status={data?.status} />
            <div className="mx-auto grid w-full max-w-6xl items-start gap-3 md:grid-cols-[1fr_250px] lg:grid-cols-[1fr_350px]">
              <Card>
                <CardContent className="pt-6">
                  {pixType}
                  {ticketType}
                  {renderPending}
                  {renderComplete}
                </CardContent>
              </Card>
              <Card>
                <CardHeader>
                  <CardTitle>Detalhes</CardTitle>
                </CardHeader>
                <CardContent className="flex flex-col gap-3">
                  <div className="flex flex-row gap-2 justify-between">
                    <span className="text-sm text-muted-foreground">
                      Quantia
                    </span>
                    <span className="text-sm text-muted-foreground">
                      {formatCurrency(data?.bill.amount)}
                    </span>
                  </div>
                  {!_.isNil(data?.bill.interestAmount) &&
                    !_.isNil(data?.bill.interestType) && (
                      <div className="flex flex-row gap-2 justify-between">
                        <span className="text-sm text-muted-foreground">
                          Juros
                        </span>
                        <span className="text-sm text-muted-foreground">
                          {data?.bill.interestAmount}%
                        </span>
                      </div>
                    )}
                  {!_.isNil(data?.bill.lateFeeAmount) &&
                    !_.isNil(data?.bill.lateFeeType) && (
                      <div className="flex flex-row gap-2 justify-between">
                        <span className="text-sm text-muted-foreground">
                          Multa
                        </span>
                        <span className="text-sm text-muted-foreground">
                          {data.bill.lateFeeType === 'FIXED'
                            ? formatCurrency(data?.bill.lateFeeAmount)
                            : data?.bill.lateFeeAmount + '%'}
                        </span>
                      </div>
                    )}
                  <hr />
                  <div className="flex flex-row gap-2 justify-between">
                    <span className="text-sm text-muted-foreground">Total</span>
                    <span className="text-sm text-muted-foreground">
                      {formatCurrency(data.bill?.totalAmount)}
                    </span>
                  </div>
                  {!_.isNil(data?.installment) && (
                    <div className="flex flex-row gap-2 justify-between">
                      <span className="text-sm text-muted-foreground">
                        Parcela {data?.installment.installmentIndex}x
                      </span>
                      <span className="text-sm text-muted-foreground">
                        {formatCurrency(data.installment.totalAmount)}
                      </span>
                    </div>
                  )}
                  <div className="flex flex-row gap-2 justify-between">
                    <span className="text-sm font-semibold">Total pago</span>
                    <span className="text-sm font-semibold">
                      {data.installment
                        ? formatCurrency(data.installment?.totalAmount)
                        : formatCurrency(data.bill?.totalAmount)}
                    </span>
                  </div>
                </CardContent>
              </Card>
            </div>
          </>
        )}
      </div>
    </>
  );
};
