import { Route, Routes } from 'react-router-dom';
import AccountList from './account-list';
import AccountAddEdit from './account-add-edit';

export default function Account() {
  return (
    <Routes>
      <Route id="AccountList" path="/" element={<AccountList />} />
      <Route id="AccountAdd" path="/add" element={<AccountAddEdit />} />
      <Route id="AccountEdit" path="/:accountId" element={<AccountAddEdit />} />
    </Routes>
  );
}
