import { Route, Routes } from 'react-router-dom';
import Layout from './components/layout';
import Login from './modules/auth/login';
import Dashboard from './modules/dashboard';
import { AuthProvider } from './hooks/useAuth';
import Transaction from './modules/transaction';
import { ProtectedRoute } from './components/protected-route';
import { TooltipProvider } from './components/ui/tooltip';
import Account from './modules/account';
import Settings from './modules/settings';
import { ThemeProvider } from './components/theme-provider';
import Bill from './modules/bill';
import Customer from './modules/customer';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Toaster } from './components/ui/toaster';
import { CustomerBills } from './modules/customer-bills';
import Category from './modules/category';

export default function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <AuthProvider>
          <TooltipProvider>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="/customer-bills/:customerId/*"
                element={<CustomerBills />}
              />
              <Route element={<ProtectedRoute />}>
                <Route element={<Layout />}>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/transactions/*" element={<Transaction />} />
                  <Route path="/accounts/*" element={<Account />} />
                  <Route path="/settings" element={<Settings />} />
                  <Route path="/bills/*" element={<Bill />} />
                  <Route path="/customers/*" element={<Customer />} />
                  <Route path="/categories/*" element={<Category />} />
                </Route>
              </Route>
            </Routes>
            <Toaster />
          </TooltipProvider>
        </AuthProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}
