import { useState } from 'react';
import { BillsNextDueDate } from './_components/bills-next-due-date';
import { ConsumByCategory } from './_components/consum-by-category';
import { LastTransactions } from './_components/last-transactions';
import Overview from './_components/overview';
import { TotalAccounts } from './_components/total-accounts';
import { DatePickerWithRange } from '@/components/date-picker-range';
import { DateRange } from 'react-day-picker';
import { startOfMonth, endOfMonth } from 'date-fns';

export default function Dashboard() {
  const [dateRange, setDateRange] = useState<DateRange>({
    from: startOfMonth(new Date()),
    to: endOfMonth(new Date()),
  });

  return (
    <>
      <div className="col-span-4 flex flex-row gap-2 justify-between items-center">
        <h1 className="text-2xl font-semibold leading-none tracking-tight">
          Dashboard
        </h1>
        <DatePickerWithRange
          dateRange={dateRange}
          onRangeChange={(range) => setDateRange(range as DateRange)}
        />
      </div>
      <div className="col-span-4 grid grid-cols-1 gap-4 lg:grid-cols-4 xl:grid-cols-4">
        <TotalAccounts
          endDate={dateRange.to as Date}
          startDate={dateRange.from as Date}
        />
      </div>
      <div className="col-span-4 lg:col-span-2 space-y-3">
        <BillsNextDueDate />
        <LastTransactions />
      </div>
      <div className="space-y-4 col-span-4 lg:col-span-2">
        <ConsumByCategory
          endDate={dateRange.to as Date}
          startDate={dateRange.from as Date}
        />
        <Overview />
      </div>
    </>
  );
}
