import EmptyTableValue from '@/components/empty-table-value';
import { BadgeCategory } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { TransactionTypeEnum } from '@/models/enums/transaction-type.enum';
import { Transaction } from '@/models/transaction';
import { ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import {
  ChevronDown,
  ChevronUp,
  Edit,
  MoreHorizontal,
  Trash2,
} from 'lucide-react';
import { Link } from 'react-router-dom';

const columns = ({
  onDelete,
}: {
  onDelete?: (transaction: Transaction) => void;
}) => {
  const columnsDef: ColumnDef<Transaction>[] = [
    {
      accessorKey: 'description',
      header: 'Descrição',
    },
    {
      accessorKey: 'amount',
      header: 'Valor',
      cell: ({ row }) => {
        const data = row.original;
        const amount = data.amount.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });
        return (
          <div className="flex flex-row gap-1">
            {data.type === TransactionTypeEnum.EXPANSE && (
              <ChevronDown className="w-4 h-5 text-red-600" />
            )}
            {data.type === TransactionTypeEnum.INCOME && (
              <ChevronUp className="w-4 h-5 text-green-600" />
            )}
            <span>{amount}</span>
          </div>
        );
      },
    },
    {
      accessorKey: 'category',
      header: 'Categoria',
      cell: ({ row }) => {
        const category = row.original.category;

        return (
          <BadgeCategory color={category.color} variant="secondary">
            {category.name}
          </BadgeCategory>
        );
      },
    },
    {
      accessorKey: 'date',
      header: 'Data',
      cell: ({ row }) => {
        const data = row.original;

        return format(new Date(data.date), 'dd/MM/yyyy HH:mm');
      },
    },
    {
      accessorKey: 'bill',
      header: 'Conta',
      cell: ({ row }) => {
        const data = row.original;
        if (!data.bill) return <EmptyTableValue />;

        return data.bill.id;
      },
    },
    {
      accessorKey: 'customer',
      header: 'Cliente',
      cell: ({ row }) => {
        const data = row.original;
        if (!data.customer) return <EmptyTableValue />;

        return data.customer.name;
      },
    },
    {
      id: 'actions',
      cell: ({ row }) => {
        const transaction = row.original;

        return (
          <div className="text-end">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <span className="sr-only">Open menu</span>
                  <MoreHorizontal className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>Ações</DropdownMenuLabel>
                <DropdownMenuItem
                  onClick={() =>
                    navigator.clipboard.writeText(transaction.id.toString())
                  }
                >
                  Copiar ID
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem asChild>
                  <Link
                    to={`/transactions/${transaction.id}`}
                    className="flex gap-2"
                  >
                    <Edit className="w-4 h-4" />
                    <span>Editar transação</span>
                  </Link>
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => onDelete?.(row.original)}
                  className="flex gap-2"
                >
                  <Trash2 className="w-4 h-4" />
                  <span>Deletar</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    },
  ] as ColumnDef<Transaction>[];

  return columnsDef;
};

export default columns;
