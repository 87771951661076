import { useToast } from '@/components/ui/use-toast';
import { categoryService } from '@/lib/services/category';
import { useMutation, useQuery, useQueryClient } from 'react-query';

const useCategory = () => {
  const queryClient = useQueryClient();
  const { toast } = useToast();
  const queryKey = 'categoryPaginatedData';

  // Create Mutation
  const createMutation = useMutation(categoryService.create, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(queryKey);
      toast({
        title: 'Sucesso!',
        variant: 'success',
        description: `Categoria ${data.name} criada com sucesso.`,
      });
    },
    onError: () => {
      toast({
        title: 'Ooops!',
        variant: 'destructive',
        description: `Houve um erro ao criar uma categoria.`,
      });
    },
  });

  // Read mutation
  const readMutation = useMutation(categoryService.read, {
    onError: () => {
      toast({
        title: 'Ooops!',
        description: `Houve ao buscar a categoria.`,
      });
    },
  });

  // List query
  const listPaginated = () => {
    return useQuery([queryKey], () => categoryService.list(), {
      keepPreviousData: true,
      staleTime: Infinity,
    });
  };

  // Update Mutation
  const updateMutation = useMutation(
    ({ id, data }: { id: string; data: any }) =>
      categoryService.update(id, data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(queryKey);
        toast({
          title: 'Sucesso!',
          variant: 'success',
          description: `Categoria ${data.name} atualizada com sucesso.`,
        });
      },
      onError: () => {
        toast({
          title: 'Ooops!',
          variant: 'destructive',
          description: `Houve um erro ao atualizar uma categoria.`,
        });
      },
    },
  );

  // Delete Mutation
  const deleteMutation = useMutation(categoryService.delete, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      toast({
        title: 'Sucesso!',
        variant: 'success',
        description: `Categoria deletada criada com sucesso.`,
      });
    },
    onError: () => {
      toast({
        title: 'Ooops!',
        description: `Houve ao deleta categoria.`,
      });
    },
  });

  return {
    createMutation,
    readMutation,
    listPaginated,
    updateMutation,
    deleteMutation,
  };
};

export default useCategory;
