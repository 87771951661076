'use client';

import { Bar, BarChart, XAxis, YAxis } from 'recharts';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart';
import { useEffect, useState } from 'react';
import { dashboardService } from '@/lib/services/dashboard';
import _ from 'lodash';
import { format } from 'date-fns';
import { Skeleton } from '@/components/ui/skeleton';

export function ConsumByCategory({
  startDate,
  endDate,
}: {
  startDate: Date;
  endDate: Date;
}) {
  const [chartData, setChartData] = useState([]);
  const [chartConfig, setChartConfig] = useState<ChartConfig>({});
  const [loading, setLoading] = useState(true);

  const fetch = async () => {
    setLoading(true);
    const result = await dashboardService.consumByCategory(
      startDate.toISOString(),
      endDate.toISOString(),
    );
    const data = result.map((item: any) => ({
      category: item.category.name.toLowerCase(),
      fill: item.category.color,
      total: item.totalConsumption,
    }));

    const chartConfigData = {
      total: {
        label: 'Total',
      },
    };
    result.forEach((item: any) => {
      _.set(chartConfigData, item.category.name.toLowerCase(), {
        label: item.category.name,
        color: item.category.color,
      });
    });
    setChartData(data);
    setChartConfig(chartConfigData);
    setLoading(false);
  };

  useEffect(() => {
    fetch();
  }, [startDate, endDate]);

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-md">Total por categoria</CardTitle>
        <CardDescription>
          {format(startDate, 'dd/MM/yyyy')} - {format(endDate, 'dd/MM/yyyy')}
        </CardDescription>
      </CardHeader>
      <CardContent>
        {loading && (
          <div className="flex-col flex gap-4">
            <Skeleton className="h-10" />
            <Skeleton className="h-6 w-40" />
            <Skeleton className="h-6 w-56" />
          </div>
        )}
        {!loading && (
          <ChartContainer config={chartConfig}>
            <BarChart
              accessibilityLayer
              data={chartData}
              layout="vertical"
              margin={{
                left: 45,
              }}
            >
              <YAxis
                dataKey="category"
                type="category"
                tickLine={false}
                tickMargin={10}
                axisLine={false}
                tickFormatter={(value) =>
                  chartConfig[value as keyof typeof chartConfig]
                    ?.label as string
                }
              />
              <XAxis dataKey="total" type="number" hide />
              <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
              <Bar dataKey="total" layout="vertical" radius={5} />
            </BarChart>
          </ChartContainer>
        )}
      </CardContent>
    </Card>
  );
}
