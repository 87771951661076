import { useParams } from 'react-router-dom';
import useCustomerBill from './hooks/useCustomerBill';
import Loader from '@/components/loader';
import { Bill } from '@/models/bill';
import { CustomerBillDetail } from './_components/customer-bill-detail';

export const CustomerBillsList = () => {
  const { customerId } = useParams();
  const { list } = useCustomerBill();
  const { data, isLoading } = list(customerId as string);

  return (
    <div className="space-y-3">
      {isLoading ? (
        <Loader />
      ) : (
        data.map((bill: Bill) => <CustomerBillDetail bill={bill} />)
      )}
    </div>
  );
};
