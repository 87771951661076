import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  ChevronDown,
  ChevronUp,
  Receipt,
  TrendingDown,
  TrendingUp,
  Wallet,
} from 'lucide-react';
import useDashboard from '../hooks/useDashboard';
import { useEffect } from 'react';
import { formatCurrency } from '@/lib/utils';
import { Skeleton } from '@/components/ui/skeleton';

export function TotalAccounts({
  startDate,
  endDate,
}: {
  startDate: Date;
  endDate: Date;
}) {
  const { getTotalAmountMutation } = useDashboard();

  useEffect(() => {
    getTotalAmountMutation.mutate({
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    });
  }, [startDate, endDate]);

  return (
    <>
      <Card>
        <CardHeader className="gap-2">
          <CardDescription>
            <div className="flex flex-row justify-between">
              <span>Saldo total</span>
              <Wallet className="w-5 h-5" />
            </div>
          </CardDescription>
          {!getTotalAmountMutation.isLoading && (
            <>
              <CardTitle>
                {formatCurrency(getTotalAmountMutation.data?.balance)}
              </CardTitle>
            </>
          )}
          {getTotalAmountMutation.isLoading && <Skeleton className="h-5" />}
        </CardHeader>
      </Card>
      <Card>
        <CardHeader className="gap-2">
          <CardDescription>
            <div className="flex flex-row justify-between">
              <span> Total de ganhos do mês</span>
              <ChevronUp className="w-5 h-5 text-green-500" />
            </div>
          </CardDescription>
          {!getTotalAmountMutation.isLoading && (
            <>
              <CardTitle>
                {formatCurrency(
                  getTotalAmountMutation.data?.income.currentTotalAmount,
                )}
              </CardTitle>
              <span className="text-xs text-muted-foreground !mt-0 flex flex-row gap-1">
                {getTotalAmountMutation.data?.income.currentTotalAmount >
                  getTotalAmountMutation.data?.income.previousTotalAmount && (
                  <TrendingUp className="w-4 h-4  text-green-500" />
                )}
                {getTotalAmountMutation.data?.income.differencePercentage}% que
                <span>o últimos mês</span>
              </span>
            </>
          )}
          {getTotalAmountMutation.isLoading && <Skeleton className="h-5" />}
        </CardHeader>
      </Card>
      <Card>
        <CardHeader className="gap-2">
          <CardDescription>
            <div className="flex flex-row justify-between">
              <span> Total de despesas do mês</span>
              <ChevronDown className="w-5 h-5 text-red-500" />
            </div>
          </CardDescription>
          {!getTotalAmountMutation.isLoading && (
            <>
              <CardTitle>
                {formatCurrency(
                  getTotalAmountMutation.data?.expanse.currentTotalAmount,
                )}
              </CardTitle>
              <span className="text-xs text-muted-foreground !mt-0 flex flex-row gap-1">
                {getTotalAmountMutation.data?.expanse.currentTotalAmount >
                  getTotalAmountMutation.data?.expanse.previousTotalAmount && (
                  <TrendingUp className="w-4 h-4 text-red-500" />
                )}
                {getTotalAmountMutation.data?.expanse.currentTotalAmount <
                  getTotalAmountMutation.data?.expanse.previousTotalAmount && (
                  <TrendingDown className="w-4 h-4 text-green-500" />
                )}
                {getTotalAmountMutation.data?.expanse.differencePercentage}% que
                <span>o últimos mês</span>
              </span>
            </>
          )}
          {getTotalAmountMutation.isLoading && <Skeleton className="h-5" />}
        </CardHeader>
      </Card>
      <Card>
        <CardHeader className="gap-2">
          <CardDescription>
            <div className="flex flex-row justify-between">
              <span>Total a pagar</span>
              <Receipt className="w-5 h-5 text-red-500" />
            </div>
          </CardDescription>
          {!getTotalAmountMutation.isLoading && (
            <>
              <CardTitle>
                {formatCurrency(getTotalAmountMutation.data?.notPaidExpanses)}
              </CardTitle>
            </>
          )}
          {getTotalAmountMutation.isLoading && <Skeleton className="h-5" />}
        </CardHeader>
      </Card>
    </>
  );
}
