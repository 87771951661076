import { useToast } from '@/components/ui/use-toast';
import { dashboardService } from '@/lib/services/dashboard';
import { useMutation } from 'react-query';

const useDashboard = () => {
  const { toast } = useToast();

  // Read mutation
  const getTotalAmountMutation = useMutation(dashboardService.totalAmounts, {
    onError: () => {
      toast({
        title: 'Ooops!',
        description: `Houve um erro ao recuperar os totais.`,
      });
    },
  });

  const getUpcomingBillsMutation = useMutation(
    dashboardService.getUpcomingBills,
    {
      onError: () => {
        toast({
          title: 'Ooops!',
          description: `Houve um erro ao recuperar os totais.`,
        });
      },
    },
  );

  const getMonthlyExpanses = useMutation(dashboardService.monthlyExpanses, {
    onError: () => {
      toast({
        title: 'Ooops!',
        description: `Houve um erro ao recuperar os totais.`,
      });
    },
  });

  return {
    getTotalAmountMutation,
    getMonthlyExpanses,
    getUpcomingBillsMutation,
  };
};

export default useDashboard;
