import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Link, useParams } from 'react-router-dom';
import useBill from './hooks/useBill';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import InstallmentsList from './_components/installments-list';
import { ChevronLeft, DollarSign } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { formatCurrency } from '@/lib/utils';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Checkbox } from '@/components/ui/checkbox';
import { CheckedState } from '@radix-ui/react-checkbox';
import _ from 'lodash';
import EmptyTableValue from '@/components/empty-table-value';

export default function BillDetail() {
  const { billId } = useParams();
  const { readMutation, payBillMutation } = useBill();
  const [open, setOpen] = useState(false);
  const [registerTransaction, setRegisterTransaction] =
    useState<CheckedState>(false);

  useEffect(() => {
    if (billId) {
      readMutation.mutate(billId);
    }
  }, []);

  const renderDetails = useMemo(() => {
    const data = readMutation.data;

    if (data) {
      const details = [
        {
          label: 'Descrição',
          value: data.description,
        },
        {
          label: 'Categoria',
          value: <Badge variant="secondary">{data.category.name}</Badge>,
        },
        {
          label: 'Conta bancária',
          value: data.account.name,
        },
        {
          label: 'Frequência',
          value: <Badge variant="outline">{data.frequency}</Badge>,
        },
        {
          label: 'Quantia',
          value: formatCurrency(data.amount),
        },
        {
          label: 'Parcelas',
          value: `${data.totalInstallments}x`,
        },
        {
          label: 'Dia de vencimento',
          value: data.dueDay ? data.dueDay : <EmptyTableValue />,
        },
        {
          label: 'Valor total',
          value: formatCurrency(data.totalAmount),
        },
        {
          label: 'Cliente',
          value: data.customer ? (
            <span>{data.customer.name}</span>
          ) : (
            <span className="italic">vazio</span>
          ),
        },
        {
          label: 'Data de inicio',
          value: format(new Date(data.startDate), 'dd/MM/yyyy'),
        },
        {
          label: 'Data de vencimento',
          value: data.dueDate ? (
            format(new Date(data.dueDate), 'dd/MM/yyyy')
          ) : (
            <EmptyTableValue />
          ),
        },
        {
          label: 'Criado em',
          value: format(new Date(data.createdAt), 'dd/MM/yyyy HH:mm'),
        },
        {
          label: 'Atualizado em',
          value: data.updatedAt ? (
            format(new Date(data.updatedAt), 'dd/MM/yyyy HH:mm')
          ) : (
            <span className="italic">vazio</span>
          ),
        },
      ];

      if (data.interestAmount && data.interestType) {
        details.push({
          label: 'Juros',
          value: data.interestAmount + '%',
        });
      }

      if (data.paidAt) {
        details.push({
          label: 'Pago em',
          value: format(new Date(data.paidAt), 'dd/MM/yyyy HH:mm'),
        });
      }

      if (data.lateFeeAmount && data.lateFeeType) {
        details.push({
          label: 'Multa',
          value: `${
            data.lateFeeType === 'PERCENTAGE'
              ? data.lateFeeAmount + '%'
              : formatCurrency(data.lateFeeAmount)
          }`,
        });
      }

      return details.map(({ label, value }, index) => (
        <div className="flex flex-col gap-2" key={index}>
          <span className="font-medium text-sm">{label}</span>
          <div className="text-sm text-muted-foreground">{value}</div>
        </div>
      ));
    }

    return null;
  }, [readMutation.data]);

  const payBill = useCallback(async () => {
    const data = { payAt: new Date().toISOString() };

    if (registerTransaction) {
      _.set(data, 'registerTransaction', registerTransaction);
    }
    await payBillMutation.mutateAsync({
      id: billId as string,
      data,
    });
    setRegisterTransaction(false);
    readMutation.mutate(billId as string);
  }, [registerTransaction]);

  return (
    <div className="grid auto-rows-max items-start gap-4 md:gap-2 lg:col-span-3">
      <div className="flex flex-col gap-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <Button variant="outline" size="icon" className="h-7 w-7" asChild>
              <Link to={'/bills'}>
                <ChevronLeft className="h-4 w-4" />
                <span className="sr-only">Voltar</span>
              </Link>
            </Button>
            <h1 className="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0">
              Conta <span className="text-muted-foreground">#{billId}</span>
            </h1>
          </div>
          <div className="flex flex-row gap-2">
            {!readMutation.data?.isPaid && (
              <AlertDialog open={open} onOpenChange={setOpen}>
                <AlertDialogTrigger asChild>
                  <Button onClick={() => setOpen(true)}>
                    <DollarSign className="w-4 h-4 mr-2" />
                    Pagar
                  </Button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>Você tem certeza?</AlertDialogTitle>
                    <AlertDialogDescription>
                      Ao realizar o pagamento, você não poderá reverter. Deseja
                      continuar ?
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <div className="flex flex-row gap-2 items-center justify-start h-full w-full">
                    <Checkbox
                      id="terms"
                      checked={registerTransaction}
                      onCheckedChange={setRegisterTransaction}
                    />
                    <label
                      htmlFor="terms"
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      Registrar transação?
                    </label>
                  </div>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Cancelar</AlertDialogCancel>
                    <AlertDialogAction asChild>
                      <Button
                        className="flex"
                        onClick={payBill}
                        loading={payBillMutation.isLoading}
                      >
                        Continuar
                      </Button>
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            )}
          </div>
        </div>
        <Card>
          <CardHeader className="p-3 bg-secondary mb-3">
            <CardTitle className="text-md">Detalhes</CardTitle>
          </CardHeader>
          <CardContent>
            {!readMutation.isLoading && (
              <div className="grid grid-cols-4 lg:gap-3 md:grid-cols-3 lg:grid-cols-4 gap-5">
                {renderDetails}
              </div>
            )}
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="p-3 bg-secondary mb-3">
            <CardTitle className="text-md">Parcelas</CardTitle>
          </CardHeader>
          <CardContent>
            <InstallmentsList billId={billId as string} />
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
