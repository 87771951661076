import { Avatar } from '@/components/ui/avatar';
import { AvatarFallback, AvatarImage } from '@radix-ui/react-avatar';
import { Outlet, useParams } from 'react-router-dom';
import useCustomerBill from '../hooks/useCustomerBill';
import { useEffect } from 'react';
import { Skeleton } from '@/components/ui/skeleton';

export const Layout = () => {
  const { readCustomerMutation } = useCustomerBill();
  const { customerId } = useParams();

  useEffect(() => {
    if (customerId) {
      readCustomerMutation.mutate(customerId);
    }
  }, [customerId]);

  return (
    <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-10 bg-muted/40 min-h-screen">
      <div className="mx-auto flex w-full max-w-6xl gap-4 justify-between items-center">
        <div className="flex flex-row gap-2">
          <span className="font-semibold">💵 My Finance</span>
        </div>
        <div className="flex flex-row gap-3 items-center">
          {readCustomerMutation.isLoading ? (
            <div className="flex items-center space-x-4">
              <div className="space-y-2">
                <Skeleton className="h-4 w-[150px]" />
                <Skeleton className="h-4 w-[80px]" />
              </div>
              <Skeleton className="h-12 w-12 rounded-full" />
            </div>
          ) : (
            <>
              <div className="flex flex-col text-end">
                <span className="font-medium">
                  {readCustomerMutation.data?.name}
                </span>
                {readCustomerMutation.data?.email ? (
                  <span className="text-muted-foreground text-sm">
                    {readCustomerMutation.data?.email}
                  </span>
                ) : null}
              </div>
              <Avatar>
                <AvatarImage
                  src={`https://ui-avatars.com/api/?name=${readCustomerMutation.data?.name
                    .split(' ')
                    .join('+')}`}
                  alt="@shadcn"
                />
                <AvatarFallback></AvatarFallback>
              </Avatar>
            </>
          )}
        </div>
      </div>
      <div className="mx-auto grid w-full max-w-6xl items-start gap-6 ">
        <Outlet />
      </div>
    </main>
  );
};
