import api from '../api';

const API_URL = `/customer`;

export const customerService = {
  create: async (data: any) => {
    const response = await api.post(API_URL, data);
    return response.data;
  },

  read: async (id: string) => {
    const response = await api.get(`${API_URL}/${id}`);
    return response.data;
  },

  list: async ({ page, pageSize }: { page: number; pageSize: number }) => {
    const response = await api.get(API_URL, { params: { page, pageSize } });
    return response.data;
  },

  update: async (id: string, data: any) => {
    const response = await api.put(`${API_URL}/${id}`, data);
    return response.data;
  },

  delete: async (id: string) => {
    const response = await api.delete(`${API_URL}/${id}`);
    return response.data;
  },
};
