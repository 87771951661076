import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';
import {
  ArrowUpDown,
  DollarSign,
  LayoutDashboardIcon,
  Moon,
  Receipt,
  Sun,
  Tag,
  User,
  Wallet2,
} from 'lucide-react';
import { useMemo } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './ui/dropdown-menu';
import { useTheme } from './theme-provider';
import Header from './header';

export default function Layout() {
  const location = useLocation();
  const { setTheme } = useTheme();

  const menuList = useMemo(() => {
    return [
      {
        title: 'Dashboard',
        href: '/',
        active: location.pathname === '/',
        icon: <LayoutDashboardIcon className="w-4 h-4" />,
      },
      {
        title: 'Transações',
        href: '/transactions',
        active: location.pathname.includes('/transactions'),
        icon: <ArrowUpDown className="w-4 h-4" />,
      },
      {
        title: 'Contas',
        href: '/bills',
        active: location.pathname.includes('/bills'),
        icon: <Receipt className="w-4 h-4" />,
      },
      {
        title: 'Contas bancárias',
        href: '/accounts',
        active: location.pathname.includes('/accounts'),
        icon: <Wallet2 className="w-4 h-4" />,
      },
      {
        title: 'Clientes',
        href: '/customers',
        active: location.pathname.includes('/customers'),
        icon: <User className="w-4 h-4" />,
      },
      {
        title: 'Categorias',
        href: '/categories',
        active: location.pathname.includes('/categories'),
        icon: <Tag className="w-4 h-4" />,
      },
    ];
  }, [location]);

  const themeSelector = useMemo(
    () => (
      <DropdownMenu>
        <DropdownMenuContent align="end">
          <DropdownMenuItem onClick={() => setTheme('light')}>
            Claro
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setTheme('dark')}>
            Escuro
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setTheme('system')}>
            Sistema
          </DropdownMenuItem>
        </DropdownMenuContent>
        <DropdownMenuTrigger asChild>
          <span className="flex h-9 w-9 items-center justify-center rounded-lg text-muted-foreground transition-colors hover:text-foreground md:h-8 md:w-8">
            <Sun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
            <Moon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
            <span className="sr-only">Toggle theme</span>
          </span>
        </DropdownMenuTrigger>
      </DropdownMenu>
    ),
    [setTheme],
  );

  return (
    <div className="relative flex min-h-screen flex-col bg-background">
      <div className="flex min-h-screen w-full flex-col bg-muted/40 ">
        <aside
          id="sibar"
          className="fixed inset-y-0 left-0 z-10 hidden w-14 flex-col border-r bg-background sm:flex"
        >
          <nav className="flex flex-col items-center gap-4 px-2 sm:py-5">
            <span className="group flex h-9 w-9 shrink-0 items-center justify-center gap-2 rounded-full bg-primary text-lg font-semibold text-primary-foreground md:h-8 md:w-8 md:text-base">
              <DollarSign className="h-4 w-4 transition-all group-hover:scale-110" />
              <span className="sr-only">Acme Inc</span>
            </span>
            {menuList.map((menu, index) => (
              <Tooltip key={index}>
                <TooltipTrigger asChild>
                  <Link
                    to={menu.href}
                    className={cn(
                      'flex h-9 w-9 items-center justify-center rounded-lg text-muted-foreground transition-colors hover:text-foreground md:h-8 md:w-8',
                      menu.active && 'bg-accent',
                    )}
                  >
                    {menu.icon}
                    <span className="sr-only">{menu.title}</span>
                  </Link>
                </TooltipTrigger>
                <TooltipContent side="right">
                  <p>{menu.title}</p>
                </TooltipContent>
              </Tooltip>
            ))}
          </nav>
          <nav className="mt-auto flex flex-col items-center gap-4 px-2 sm:py-5">
            {themeSelector}
          </nav>
        </aside>
        <div className="flex flex-col sm:gap-4 sm:py-4 sm:pl-14">
          <Header />
          <main className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8 lg:grid-cols-3 xl:grid-cols-3">
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  );
}
