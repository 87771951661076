import _ from 'lodash';
import api from '../api';

const API_URL = `/payment`;

export const paymentService = {
  paymentMethods: async () => {
    const response = await api.get(`${API_URL}/methods`);
    return response.data;
  },

  createPaymentIntent: async ({
    billId,
    installmentId,
    data,
  }: {
    billId: string;
    installmentId?: string | null;
    data: any;
  }) => {
    let response;
    if (!_.isNil(installmentId)) {
      response = await api.post(
        `${API_URL}/bill/${billId}/installment/${installmentId}`,
        data,
      );
    } else {
      response = await api.post(`${API_URL}/bill/${billId}`, data);
    }
    return response.data;
  },

  findOne: async (id: string) => {
    const response = await api.get(`${API_URL}/${id}`);
    return response.data;
  },
};
