import api from '../api';
import { formatString } from '../utils';

const API_URL = `/bill/customer/{customerId}`;

export const customerBillService = {
  list: async (customerId: string) => {
    const response = await api.get(`${formatString(API_URL, { customerId })}`);
    return response.data;
  },

  readBill: async ({
    customerId,
    billId,
  }: {
    customerId: string;
    billId: string;
  }) => {
    const response = await api.get(
      `${formatString(API_URL, {
        customerId,
      })}/bill/${billId}`,
    );
    return response.data;
  },

  readBillInstallment: async ({
    customerId,
    billId,
    installmentIndex,
  }: {
    customerId: string;
    billId: string;
    installmentIndex: string;
  }) => {
    const response = await api.get(
      `${formatString(API_URL, {
        customerId,
      })}/bill/${billId}/installment/${installmentIndex}`,
    );
    return response.data;
  },
};
