import { Route, Routes } from 'react-router-dom';
import BillList from './bill-list';
import BillAddEdit from './bill-add-edit';
import BillDetail from './bill-datail';

export default function Bill() {
  return (
    <Routes>
      <Route id="BillList" path="/" element={<BillList />} />
      <Route id="BillAdd" path="/add" element={<BillAddEdit />} />
      <Route id="BillEdit" path="/:billId" element={<BillAddEdit />} />
      <Route id="BillDetails" path="/detail/:billId" element={<BillDetail />} />
    </Routes>
  );
}
