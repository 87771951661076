import { DollarSign, RefreshCcw } from 'lucide-react';

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { useCallback, useEffect, useMemo } from 'react';
import useDashboard from '../hooks/useDashboard';
import { cn, formatCurrency } from '@/lib/utils';
import { format } from 'date-fns';
import Loader from '@/components/loader';
import { Link } from 'react-router-dom';
import { Badge } from '@/components/ui/badge';

export function BillsNextDueDate() {
  const { getUpcomingBillsMutation } = useDashboard();

  useEffect(() => {
    fetch();
  }, []);

  const fetch = () => {
    getUpcomingBillsMutation.mutate();
  };

  const statusBadge = useCallback((billOrInstallment: any) => {
    const isPaid = billOrInstallment.isPaid;
    const dueDate = billOrInstallment.dueDate;
    const isDue = new Date().getTime() > new Date(dueDate).getTime();
    const label = isPaid ? 'Pago' : isDue ? 'Vencido' : 'Pendente';

    return (
      <Badge
        variant="outline"
        className={cn(
          isPaid
            ? 'bg-green-500/30'
            : isDue
              ? 'bg-red-500/30'
              : 'bg-orange-500/30',
          'border-none',
        )}
      >
        {label}
      </Badge>
    );
  }, []);

  const bills = useMemo(() => {
    if (
      !getUpcomingBillsMutation.isLoading &&
      getUpcomingBillsMutation.data?.bills.length <= 0
    )
      return null;

    return (
      <div>
        <h2 className="font-semibold tracking-tight text-md mb-5">Contas</h2>
        {getUpcomingBillsMutation.data?.bills.map(
          (bill: any, index: number) => (
            <div
              className="space-y-2 border-t py-3 hover:bg-secondary px-4"
              key={index}
            >
              <div className="grid grid-cols-5 gap-x-3">
                <span className="font-semibold flex items-center text-sm gap-2 flex-row">
                  {statusBadge(bill)}
                  <span>Conta #{bill.id}</span>
                </span>
                <div className="text-muted-foreground flex items-center justify-center  overflow-hidden">
                  <span className="text-sm truncate" title={bill.description}>
                    {bill.description}
                  </span>
                </div>
                <span className="flex items-center text-sm justify-center">
                  {formatCurrency(bill.totalAmount)}
                </span>
                <span className="flex items-center text-sm justify-center">
                  {format(new Date(bill.dueDate), 'dd/MM/yyyy')}
                </span>
                <div className="text-end">
                  <Button className="w-32" asChild>
                    <Link to={`/bills/detail/${bill.id}`}>
                      <DollarSign className="w-4 h-4" />
                      Pagar
                    </Link>
                  </Button>
                </div>
              </div>
            </div>
          ),
        )}
      </div>
    );
  }, [getUpcomingBillsMutation]);

  const installments = useMemo(() => {
    if (
      !getUpcomingBillsMutation.isLoading &&
      getUpcomingBillsMutation.data?.installments.length <= 0
    )
      return null;

    return (
      <div>
        <h2 className="font-semibold tracking-tight text-md mb-5">Parcelas</h2>
        {getUpcomingBillsMutation.data?.installments.map(
          (installment: any, index: number) => (
            <div
              className="space-y-2 border-t py-3 hover:bg-secondary px-4"
              key={index}
            >
              <div className="grid grid-cols-5 gap-x-3">
                <div className="font-semibold flex items-center overflow-hidden gap-2 col-span-2">
                  {statusBadge(installment)}
                  <span
                    className="text-sm truncate"
                    title={installment.description}
                  >
                    Parcela {installment.installmentIndex}x da conta #
                    {installment.bill.id}
                  </span>
                </div>
                <span className="flex items-center text-sm justify-center">
                  {formatCurrency(installment.totalAmount)}
                </span>
                <span className="flex items-center text-sm justify-center">
                  {format(new Date(installment.dueDate), 'dd/MM/yyyy')}
                </span>
                <div className="text-end">
                  <Button className="w-32" asChild>
                    <Link to={`/bills/detail/${installment.bill.id}`}>
                      <DollarSign className="w-4 h-4" />
                      Pagar
                    </Link>
                  </Button>
                </div>
              </div>
            </div>
          ),
        )}
      </div>
    );
  }, [getUpcomingBillsMutation]);

  return (
    <Card className="h-full">
      <CardHeader className="flex flex-row justify-between">
        <div>
          <CardTitle className="font-semibold tracking-tight text-md">
            Contas próximas do vencimento
          </CardTitle>
          <CardDescription>Contas que vencerão em 10 dias</CardDescription>
        </div>
        <Button variant="outline" className="h-10 w-12" onClick={fetch}>
          <RefreshCcw className="w-4 h-4" />
        </Button>
      </CardHeader>
      <CardContent>
        {getUpcomingBillsMutation.isLoading ? (
          <Loader />
        ) : (
          <div className="space-y-2">
            {bills}
            {installments}
            {!bills && !installments && (
              <span className="text-muted-foreground text-sm flex flex-col items-center w-full justify-center h-20 ">
                Não há parcelas ou contas próximas do vencimento!
              </span>
            )}
          </div>
        )}
      </CardContent>
    </Card>
  );
}
