import EmptyTableValue from '@/components/empty-table-value';
import { Badge, BadgeCategory } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Bill } from '@/models/bill';
import { ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import {
  DollarSign,
  Edit,
  EyeIcon,
  MoreHorizontal,
  Trash2,
} from 'lucide-react';
import { Link } from 'react-router-dom';
import { Installment } from '@/models/installment';
import { cn, formatCurrency } from '@/lib/utils';

export const columns = ({ onDelete }: { onDelete: (bill: Bill) => void }) => {
  const columns: ColumnDef<Bill>[] = [
    {
      accessorKey: 'id',
      header: 'ID',
    },
    {
      accessorKey: 'category',
      header: 'Categoria',
      cell: ({ row }) => {
        const category = row.original.category;

        return (
          <BadgeCategory color={category.color} variant="secondary">
            {category?.name}
          </BadgeCategory>
        );
      },
    },
    {
      accessorKey: 'description',
      header: 'Descrição',
    },
    {
      accessorKey: 'frequency',
      header: 'Frequência',
      cell: ({ row }) => {
        const frequency = row.original.frequency;

        return <Badge variant="outline">{frequency}</Badge>;
      },
    },
    {
      accessorKey: 'totalAmount',
      header: 'Valor total',
      cell: ({ row }) => {
        const amount = row.original.totalAmount;

        return formatCurrency(amount);
      },
    },
    {
      accessorKey: 'paidAt',
      header: 'Pago em',
      cell: ({ row }) => {
        const paidAt = row.original.paidAt as Date;

        if (paidAt) return format(paidAt, 'dd/MM/yyyy');

        return <EmptyTableValue />;
      },
    },
    {
      accessorKey: 'customer',
      header: 'Cliente',
      cell: ({ row }) => {
        const customer = row.original.customer;

        if (customer) return customer.name;

        return <EmptyTableValue />;
      },
    },
    {
      header: 'Parcelas',
      cell: ({ row }) => {
        const totalInstallment = row.original._count.installment;
        if (totalInstallment > 0)
          return <Badge variant="outline">{totalInstallment}</Badge>;

        return <EmptyTableValue />;
      },
    },
    {
      id: 'actions',
      cell: ({ row }) => {
        const bill = row.original;

        return (
          <div className="text-end space-x-2">
            <Button variant="ghost" className="h-8 w-8 p-0" asChild>
              <Link to={`/bills/detail/${bill.id}`}>
                <span className="sr-only">Open menu</span>
                <EyeIcon className="w-4 h-4" />
              </Link>
            </Button>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <span className="sr-only">Open menu</span>
                  <MoreHorizontal className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>Ações</DropdownMenuLabel>
                <DropdownMenuItem
                  onClick={() =>
                    navigator.clipboard.writeText(bill.id.toString())
                  }
                >
                  Copiar ID
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem asChild>
                  <Link to={`/bills/${bill.id}`} className="flex gap-2">
                    <Edit className="w-4 h-4" />
                    <span>Editar conta</span>
                  </Link>
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => onDelete?.(row.original)}
                  className="flex gap-2"
                >
                  <Trash2 className="w-4 h-4" />
                  <span>Deletar</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    },
  ];

  return columns;
};

export const installmentsColumns = ({
  onPay,
}: {
  onPay: (installment: Installment) => void;
}) => {
  return [
    {
      accessorKey: 'installmentIndex',
      header: 'Parcela',
    },
    {
      accessorKey: 'dueDate',
      header: 'Data de vencimento',
      cell: ({ row }) => {
        const original = row.original;

        return format(new Date(original.dueDate), 'dd/MM/yyyy');
      },
    },
    {
      accessorKey: 'isPaid',
      header: 'Pago',
      cell: ({ row }) => {
        const isPaid = row.original?.isPaid;
        const dueDate = row.original.dueDate;
        const isDue = new Date().getTime() > new Date(dueDate).getTime();
        const label = isPaid ? 'Pago' : isDue ? 'Vencido' : 'Pendente';

        return (
          <Badge
            variant="outline"
            className={cn(
              isPaid
                ? 'bg-green-500/30'
                : isDue
                  ? 'bg-red-500/30'
                  : 'bg-orange-500/30',
              'border-none',
            )}
          >
            {label}
          </Badge>
        );
      },
    },
    {
      accessorKey: 'totalAmount',
      header: 'Quantia',
      cell: ({ row }) => {
        const original = row.original;

        return formatCurrency(original.totalAmount);
      },
    },
    {
      accessorKey: 'paidAt',
      header: 'Pago em',
      cell: ({ row }) => {
        const original = row.original;

        if (original.paidAt) {
          return format(new Date(original.paidAt), 'dd/MM/yyyy HH:mm');
        }

        return <EmptyTableValue />;
      },
    },
    {
      id: 'actions',
      cell: ({ row }) => {
        const installment = row.original;

        return (
          <div className="text-end space-x-2">
            <Button variant="ghost" className="h-8 w-8 p-0" asChild>
              <Link to={`/bills/detail/${installment.id}`}>
                <span className="sr-only">Open menu</span>
                <EyeIcon className="w-4 h-4" />
              </Link>
            </Button>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <span className="sr-only">Open menu</span>
                  <MoreHorizontal className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>Ações</DropdownMenuLabel>
                <DropdownMenuItem
                  onClick={() =>
                    navigator.clipboard.writeText(installment.id.toString())
                  }
                >
                  Copiar ID
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                {!installment.isPaid && (
                  <DropdownMenuItem onClick={() => onPay(installment)}>
                    <DollarSign className="w-4 h-4" />
                    <span>Pagar</span>
                  </DropdownMenuItem>
                )}
                <DropdownMenuItem asChild>
                  <Link to={`/bills/${installment.id}`} className="flex gap-2">
                    <Edit className="w-4 h-4" />
                    <span>Editar parcela</span>
                  </Link>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    },
  ] as ColumnDef<Installment>[];
};
