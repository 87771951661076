import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible';
import { cn, formatCurrency } from '@/lib/utils';
import { Bill } from '@/models/bill';
import { format } from 'date-fns';
import { ChevronDown, DollarSign } from 'lucide-react';
import { useMemo } from 'react';
import { BillInstallmentDetail } from './bill-installment-detail';
import { Accordion } from '@/components/ui/accordion';
import * as _ from 'lodash';
import { Link } from 'react-router-dom';

export const CustomerBillDetail = ({ bill }: { bill: Bill }) => {
  const statusBadge = useMemo(() => {
    const isPaid = bill.isPaid;
    const dueDate = bill.dueDate;
    const isDue = new Date().getTime() > new Date(dueDate).getTime();
    const label = isPaid ? 'Pago' : isDue ? 'Vencido' : 'Pendente';

    return (
      <Badge
        variant="outline"
        className={cn(
          isPaid
            ? 'bg-green-500/30'
            : isDue
              ? 'bg-red-500/30'
              : 'bg-orange-500/30',
          'border-none',
        )}
      >
        {label}
      </Badge>
    );
  }, []);

  const renderDetails = useMemo(() => {
    if (bill) {
      const details = [
        {
          label: 'Descrição',
          value: bill.description,
        },
        {
          label: 'Categoria',
          value: <Badge variant="secondary">{bill?.category?.name}</Badge>,
        },
        {
          label: 'Frequência',
          value: <Badge variant="outline">{bill.frequency}</Badge>,
        },
        {
          label: 'Valor total',
          value: formatCurrency(bill.totalAmount),
        },
        {
          label: 'Parcelas',
          value: `${bill.totalInstallments}x`,
        },
        {
          label: 'Dia de vencimento',
          value: bill.dueDay,
        },
        {
          label: 'Data de inicio',
          value: bill.startDate
            ? format(new Date(bill.startDate), 'dd/MM/yyyy')
            : null,
        },
        {
          label: 'Data de vencimento (final)',
          value: bill.dueDate
            ? format(new Date(bill.dueDate), 'dd/MM/yyyy')
            : null,
        },
        {
          label: 'Criado em',
          value: format(new Date(bill.createdAt), 'dd/MM/yyyy HH:mm'),
        },
        {
          label: 'Atualizado em',
          value: bill.updatedAt ? (
            format(new Date(bill.updatedAt), 'dd/MM/yyyy HH:mm')
          ) : (
            <span className="italic">vazio</span>
          ),
        },
      ];

      return details.map(({ label, value }, index) => {
        if (_.isNil(value)) return null;
        return (
          <div className="flex flex-col gap-2" key={index}>
            <span className="font-medium text-sm">{label}</span>
            <div className="text-sm text-muted-foreground">{value}</div>
          </div>
        );
      });
    }

    return null;
  }, [bill]);

  const renderInstallments = useMemo(() => {
    if (bill.installment && bill.installment.length > 0) {
      return (
        <Accordion type="single" className="space-y-3 w-full" collapsible>
          {bill.installment.map((installment) => (
            <BillInstallmentDetail
              installment={installment}
              key={installment.id}
            />
          ))}
        </Accordion>
      );
    }
  }, [bill.installment]);

  return (
    <Collapsible className="border rounded-md">
      <CollapsibleTrigger asChild>
        <div className="rounded-t-md bg-secondary p-2 px-4 flex flex-row gap-2 items-center justify-between cursor-pointer h-14">
          <div className=" flex flex-row gap-2 w-full">
            {statusBadge}
            <span className="font-medium truncate text-sm">
              Conta #{bill.id}
            </span>
          </div>
          <div className="flex flex-row gap-3 items-center">
            <div className=" flex flex-row gap-2 items-center truncate">
              <span className="font-semibold">
                {formatCurrency(bill.amount)}
              </span>
              <span className="text-sm text-muted-foreground">
                {bill.totalInstallments}x
              </span>
            </div>
            <ChevronDown className="w-5 h-5 text-muted-foreground" />
          </div>
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent className="p-5 bg-background">
        <div className="flex flex-col gap-5">
          <div className="grid lg:gap-3 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">
            {renderDetails}
          </div>

          {bill.totalInstallments > 1 ? (
            <>
              <h1 className="font-semibold">Parcelas</h1>
              {renderInstallments}
            </>
          ) : !bill.isPaid ? (
            <div className="space-y-2 text-end">
              <Button asChild>
                <Link
                  to={`/customer-bills/${bill.customerId}/bill/${bill.id}/checkout`}
                >
                  <DollarSign className="w-4 h-4 mr-2" />
                  <span>Pagar</span>
                </Link>
              </Button>
            </div>
          ) : null}
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
};
