import { useToast } from '@/components/ui/use-toast';
import { billService } from '@/lib/services/bill';
import { installmentService } from '@/lib/services/installment';
import { useMutation, useQuery, useQueryClient } from 'react-query';

const useBill = () => {
  const queryClient = useQueryClient();
  const { toast } = useToast();
  const queryKey = 'billPaginatedData';
  const queryInstallmentsKey = 'installmentsPaginatedData';

  // Create mutation
  const createMutation = useMutation(billService.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      toast({
        title: 'Sucesso!',
        variant: 'success',
        description: `Conta criada com sucesso.`,
      });
    },
    onError: () => {
      toast({
        title: 'Ooops!',
        variant: 'destructive',
        description: `Houve um erro ao criar uma conta`,
      });
    },
  });

  // Read mutation
  const readMutation = useMutation(billService.read, {
    onError: () => {
      toast({
        title: 'Ooops!',
        description: `Houve um erro ao criar um cliente.`,
      });
    },
  });

  // List query
  const listPaginated = (
    page: number,
    pageSize: number,
    sortBy?: string,
    sortOrder?: 'desc' | 'asc',
  ) => {
    return useQuery(
      [queryKey, page, pageSize],
      () => billService.list({ page, pageSize, sortBy, sortOrder }),
      {
        keepPreviousData: true,
        select: (data) => ({
          items: data.items || [],
          totalPages: data.totalPages || 0,
          pageIndex: page,
          totalItems: data.totalItems,
        }),
      },
    );
  };

  // Update mutation
  const updateMutation = useMutation(
    ({ id, data }: { id: string; data: any }) => billService.update(id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
    },
  );

  // Delete mutation
  const deleteMutation = useMutation(billService.delete, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      toast({
        title: 'Sucesso!',
        variant: 'success',
        description: `Conta deletada com sucesso.`,
      });
    },
    onError: () => {
      toast({
        title: 'Ooops!',
        variant: 'destructive',
        description: `Houve um erro ao deletar conta.`,
      });
    },
  });

  const payBillMutation = useMutation(billService.payBill, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      queryClient.invalidateQueries(queryInstallmentsKey);
      toast({
        title: 'Sucesso!',
        variant: 'success',
        description: `Conta atualizada com sucesso.`,
      });
    },
    onError: () => {
      toast({
        title: 'Ooops!',
        variant: 'destructive',
        description: `Houve um erro ao atualizar conta.`,
      });
    },
  });

  const payInstallmentMutation = useMutation(
    installmentService.payInstallment,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryInstallmentsKey);
        toast({
          title: 'Sucesso!',
          variant: 'success',
          description: `Parcela atualizada com sucesso.`,
        });
      },
      onError: () => {
        toast({
          title: 'Ooops!',
          variant: 'destructive',
          description: `Houve um erro ao atualizar conta.`,
        });
      },
    },
  );
  // List query
  const listInstallmentsPaginated = (
    billId: string,
    page: number,
    pageSize: number,
    sortBy?: string,
    sortOrder?: 'desc' | 'asc',
  ) => {
    return useQuery(
      [queryInstallmentsKey, billId, page, pageSize],
      () =>
        installmentService.list({ billId, page, pageSize, sortBy, sortOrder }),
      {
        keepPreviousData: true,
        staleTime: Infinity,
        select: (data) => ({
          items: data.items || [],
          totalPages: data.totalPages || 0,
          pageIndex: page,
          totalItems: data.totalItems,
        }),
      },
    );
  };

  return {
    createMutation,
    readMutation,
    payBillMutation,
    listInstallmentsPaginated,
    listPaginated,
    payInstallmentMutation,
    updateMutation,
    deleteMutation,
  };
};

export default useBill;
