import api from '../api';

const API_URL = `/transaction`;

export const transactionService = {
  create: async (data: any) => {
    const response = await api.post(API_URL, data);
    return response.data;
  },

  createMany: async (data: any) => {
    const response = await api.post(`${API_URL}/many`, data);
    return response.data;
  },

  read: async (id: string) => {
    const response = await api.get(`${API_URL}/${id}`);
    return response.data;
  },

  list: async ({
    page,
    pageSize,
    sortOrder,
    sortBy,
  }: {
    page: number;
    pageSize: number;
    sortOrder?: 'asc' | 'desc';
    sortBy?: string;
  }) => {
    const response = await api.get(API_URL, {
      params: { page, pageSize, sortOrder, sortBy },
    });
    return response.data;
  },

  update: async (id: string, data: any) => {
    const response = await api.patch(`${API_URL}/${id}`, data);
    return response.data;
  },

  delete: async (id: string) => {
    const response = await api.delete(`${API_URL}/${id}`);
    return response.data;
  },
};
