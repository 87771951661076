import { CheckCircle, CircleX, HourglassIcon } from 'lucide-react';

const statusIcons = {
  PENDING: <HourglassIcon className="text-yellow-500 text-2xl" />,
  APPROVED: <CheckCircle className="text-green-500 text-2xl" />,
  CANCELED: <CircleX className="text-red-500 text-2xl" />,
  REJECTED: <CircleX className="text-red-500 text-2xl" />,
};

const PaymentStatus = ({
  status,
}: {
  status: 'PENDING' | 'APPROVED' | 'CANCELED' | 'REJECTED';
}) => {
  return (
    <div className="rounded-lg border border-slate-200 bg-white text-slate-950 shadow-sm dark:border-slate-800 dark:bg-slate-950 dark:text-slate-50 flex flex-row justify-center items-center p-4 gap-2">
      <div className="flex-shrink-0">
        {statusIcons[status] || statusIcons.PENDING}
      </div>
      <div className="text-lg font-medium text-center">
        {status === 'PENDING' && 'Pendente'}
        {status === 'APPROVED' && 'Aprovado'}
        {status === 'CANCELED' && 'Cancelado'}
        {status === 'REJECTED' && 'Rejeitado'}
        {!status || (!statusIcons[status] && 'Status: Desconhecido')}
      </div>
    </div>
  );
};

export default PaymentStatus;
