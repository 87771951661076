import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import useBill from '../hooks/useBill';
import { Bill } from '@/models/bill';

export const DeleteDialog = ({
  bill,
  onClose,
}: {
  bill: Bill;
  onClose?: () => void;
}) => {
  const [open, setOpen] = useState(false);
  const { deleteMutation } = useBill();
  useEffect(() => {
    if (!_.isNil(bill)) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [bill]);

  const deleteHandler = useCallback(async () => {
    if (_.isNil(bill)) return;

    try {
      await deleteMutation.mutateAsync(String(bill.id));
    } finally {
      onOpenHandler(false);
    }
  }, [bill]);

  const onOpenHandler = useCallback((state: boolean) => {
    setOpen(state);
    if (!state) {
      onClose?.();
    }
  }, []);

  return (
    <AlertDialog open={open} onOpenChange={onOpenHandler}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Você tem certeza?</AlertDialogTitle>
          <AlertDialogDescription>
            <div className="space-y-4">
              <p>
                Você tem certeza que deseja excluir essa conta? Ao continuar
                essa ação não podera ser revertida.
              </p>
            </div>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          {!deleteMutation.isLoading && (
            <AlertDialogCancel>Cancelar</AlertDialogCancel>
          )}
          <AlertDialogAction asChild>
            <Button
              className="flex"
              onClick={deleteHandler}
              loading={deleteMutation.isLoading}
            >
              Continuar
            </Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
