import api from '../api';
import { formatString } from '../utils';

const API_URL = `/bill/{billId}/installments`;

export const installmentService = {
  create: async (billId: string, data: any) => {
    const response = await api.post(
      `${formatString(API_URL, { billId })}`,
      data,
    );
    return response.data;
  },

  read: async (billId: string, id: string) => {
    const response = await api.get(
      `${formatString(API_URL, { billId })}/${id}`,
    );
    return response.data;
  },

  payInstallment: async ({
    billId,
    data,
    id,
  }: {
    billId: string;
    id: string;
    data: any;
  }) => {
    const response = await api.patch(
      `${formatString(API_URL, { billId })}/${id}/pay`,
      data,
    );
    return response.data;
  },

  list: async ({
    billId,
    page,
    pageSize,
    sortOrder,
    sortBy,
  }: {
    billId: string;
    page: number;
    pageSize: number;
    sortOrder?: 'asc' | 'desc';
    sortBy?: string;
  }) => {
    const response = await api.get(`${formatString(API_URL, { billId })}`, {
      params: { page, pageSize, sortOrder, sortBy },
    });
    return response.data;
  },

  update: async (billId: string, id: string, data: any) => {
    const response = await api.patch(
      `${formatString(API_URL, { billId })}/${id}`,
      data,
    );
    return response.data;
  },

  delete: async (billId: string, id: string) => {
    const response = await api.delete(
      `${formatString(API_URL, { billId })}/${id}`,
    );
    return response.data;
  },
};
