import DataTable from '@/components/data-table';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import useAccount from '@/hooks/useAccount';
import { File, PlusCircle } from 'lucide-react';
import { Link } from 'react-router-dom';
import { columns } from './_components/columns';
import { PaginationState } from '@tanstack/react-table';
import { useCallback, useMemo, useState } from 'react';
import { Account } from '@/models/account';
import { DeleteDialog } from './_components/delete-dialog';
import _ from 'lodash';

export default function AccountList() {
  const [account, setAccount] = useState<Account | null>(null);
  const { listPaginated } = useAccount();
  const { data, isLoading } = listPaginated();
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const onDelete = useCallback((account: Account) => {
    setAccount(account);
  }, []);

  const onDialogClose = useCallback(() => {
    setAccount(null);
  }, []);

  const deleteDialog = useMemo(() => {
    if (_.isNil(account)) return null;

    return <DeleteDialog account={account} onClose={onDialogClose} />;
  }, [account]);

  return (
    <>
      {deleteDialog}
      <div className="grid auto-rows-max items-start gap-4 md:gap-2 lg:col-span-3">
        <div className="flex items-center">
          <div className="ml-auto flex items-center gap-2">
            <Button size="sm" variant="outline" className="h-8 gap-1">
              <File className="h-3.5 w-3.5" />
              <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                Exportar
              </span>
            </Button>
            <Button size="sm" className="h-8 gap-1" asChild>
              <Link to="/accounts/add">
                <PlusCircle className="h-3.5 w-3.5" />
                <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                  Adicionar conta
                </span>
              </Link>
            </Button>
          </div>
        </div>
        <Card>
          <CardHeader>
            <CardTitle>Contas bancárias</CardTitle>
            <CardDescription>
              Administre todas as contas cadastras no sistema.
            </CardDescription>
            <CardContent className="p-0">
              <DataTable
                columns={columns({ onDelete })}
                loading={isLoading}
                data={data || []}
                pagination={pagination}
                pageCount={1}
                onPagination={setPagination}
              />
            </CardContent>
          </CardHeader>
        </Card>
      </div>
    </>
  );
}
