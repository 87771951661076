import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Transaction } from '@/models/transaction';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import useTransaction from '../hooks/useTransaction';

export const DeleteDialog = ({
  transaction,
  onClose,
}: {
  transaction?: Transaction;
  onClose?: () => void;
}) => {
  const [open, setOpen] = useState(false);
  const { deleteMutation } = useTransaction();
  useEffect(() => {
    if (!_.isNil(transaction)) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [transaction]);

  const deleteHandler = useCallback(async () => {
    if (_.isNil(transaction)) return;

    try {
      await deleteMutation.mutateAsync(String(transaction.id));
    } finally {
      onOpenHandler(false);
    }
  }, [transaction]);

  const onOpenHandler = useCallback((state: boolean) => {
    setOpen(state);
    if (!state) {
      onClose?.();
    }
  }, []);

  return (
    <AlertDialog open={open} onOpenChange={onOpenHandler}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Você tem certeza?</AlertDialogTitle>
          <AlertDialogDescription>
            <div className="space-y-4">
              <p>
                Você tem certeza que deseja excluir essa transação? Ao
                continuar, não podera ser revertido.
              </p>
            </div>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          {!deleteMutation.isLoading && (
            <AlertDialogCancel>Cancelar</AlertDialogCancel>
          )}
          <AlertDialogAction asChild>
            <Button
              className="flex"
              onClick={deleteHandler}
              loading={deleteMutation.isLoading}
            >
              Continuar
            </Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
