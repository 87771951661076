import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { NumericFormat } from 'react-number-format';

import { Input } from '@/components/ui/input';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import Loader from '@/components/loader';
import useAccount from '@/hooks/useAccount';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { AccountTypeEnum } from '@/models/enums/account-type.enum';

const formSchema = z.object({
  name: z.string().min(1, 'O nome é obrigatório'),
  type: z.string().min(1, 'O tipo de conta é obrigatório'),
  balance: z.number().refine(
    (value) => {
      const numValue = Number(value);
      return !isNaN(numValue) && numValue >= -9999999 && numValue <= 9999999;
    },
    {
      message:
        'O valor deve ser um número válido entre menos ou máximo de 9.999.999',
    },
  ),
});

export default function AccountAddEdit() {
  const { accountId } = useParams();
  const { createMutation, readMutation, updateMutation } = useAccount();
  const navigate = useNavigate();

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      type: '',
      balance: 0,
      name: '',
    },
  });

  useEffect(() => {
    fechCustomer();
  }, [accountId]);

  const fechCustomer = async () => {
    if (accountId) {
      const data = await readMutation.mutateAsync(accountId);
      form.reset({ ...data });
    }
  };

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const data = { ...values };
    if (!accountId) {
      await createMutation.mutateAsync(data);
    } else {
      await updateMutation.mutateAsync({ id: accountId, data });
    }

    navigate('/accounts');
  };

  const renderHeader = useMemo(() => {
    return (
      <CardHeader>
        <CardTitle>{!accountId ? 'Adicionar conta' : 'Editar conta'}</CardTitle>
        {!accountId && (
          <CardDescription>
            Insira todos os dados abaixo para cadastrar um nova conta.
          </CardDescription>
        )}
      </CardHeader>
    );
  }, [accountId]);

  const typeSelect = useMemo(() => {
    return (
      <FormField
        control={form.control}
        name="type"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Tipo de conta</FormLabel>
            <FormControl>
              <Select onValueChange={field.onChange} value={field.value}>
                <SelectTrigger>
                  <SelectValue placeholder="Selecione um tipo" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value={AccountTypeEnum.CHECKING}>
                      Corrente
                    </SelectItem>
                    <SelectItem value={AccountTypeEnum.CREDIT}>
                      Crédito
                    </SelectItem>
                    <SelectItem value={AccountTypeEnum.INVESTMENT}>
                      Investimento
                    </SelectItem>
                    <SelectItem value={AccountTypeEnum.SAVINGS}>
                      Poupança
                    </SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    );
  }, [form]);

  return (
    <div className="grid auto-rows-max items-start gap-4 md:gap-2 lg:col-span-3">
      <Card>
        {renderHeader}
        <CardContent>
          {readMutation.isLoading ? (
            <Loader />
          ) : (
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-8"
              >
                <div className="grid lg:grid-cols-2 gap-3">
                  {typeSelect}
                  <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Nome</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="balance"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Saldo</FormLabel>
                        <FormControl>
                          <NumericFormat
                            value={field.value}
                            onValueChange={(values) =>
                              field.onChange(values.floatValue)
                            }
                            customInput={Input}
                            prefix="R$ "
                            allowNegative={true}
                            thousandSeparator="."
                            decimalSeparator=","
                            fixedDecimalScale={true}
                            decimalScale={2}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="flex flex-row gap-2 items-center justify-end">
                  {!createMutation.isLoading && (
                    <Button type="button" variant="outline" asChild>
                      <Link to="/accounts">Cancelar</Link>
                    </Button>
                  )}
                  <Button
                    type="submit"
                    loading={
                      createMutation.isLoading || updateMutation.isLoading
                    }
                  >
                    Salvar
                  </Button>
                </div>
              </form>
            </Form>
          )}
        </CardContent>
      </Card>
    </div>
  );
}
