import { Route, Routes } from 'react-router-dom';
import TransactionList from './transaction-list';
import TransactionEditAdd from './transaction-edit-add';

export default function Transaction() {
  return (
    <Routes>
      <Route id="TransactionList" path="/" element={<TransactionList />} />
      <Route id="TransactionAdd" path="/add" element={<TransactionEditAdd />} />
      <Route
        id="TransactionEdit"
        path="/:transactionId"
        element={<TransactionEditAdd />}
      />
    </Routes>
  );
}
