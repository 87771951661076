import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import Loader from '@/components/loader';
import useBill from './hooks/useBill';
import { BillFrequencyEnum } from '@/models/enums/fill-frequency.enum';
import { CategoryCombobox } from '@/components/category-combobox';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { AccountCombobox } from '@/components/account-combobox';
import { CustomerCombobox } from '@/components/customer-combobox';
import { Switch } from '@/components/ui/switch';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { CalendarIcon } from 'lucide-react';
import { Calendar } from '@/components/ui/calendar';
import { format } from 'date-fns';
import { cn, formatCurrency } from '@/lib/utils';
import { LateFeeTypeEnum } from '@/models/enums/late-fee-type.enum';
import { InterestTypeEnum } from '@/models/enums/interest-type.enum';
import { NumericFormat } from 'react-number-format';

type formProps = {
  frequency: BillFrequencyEnum;
  amount: number;
  accountId: string;
  categoryId: string;
  description: string;
  isPaid?: boolean;
  paidAt?: null | Date;
  customerId: string;
  dueDay: string;
  totalInstallments: string;
  startDate: string | Date;
  lateFeeType: string;
  lateFeeAmount: string;
  interestType: string;
  interestAmount: string;
  dueDate: Date | null;
};

const formSchema = z.object({
  frequency: z.nativeEnum(BillFrequencyEnum),
  amount: z.number().refine(
    (value) => {
      const numValue = Number(value);
      return !isNaN(numValue) && numValue >= 1 && numValue <= 9999999;
    },
    {
      message: 'O valor deve ser um número válido entre 1 e 9.999.999',
    },
  ),
  accountId: z.string().min(1, 'O campo de conta bancária é obrigatório'),
  categoryId: z.string().min(1, 'O campo de categoria é obrigatório'),
  description: z.string().min(1, 'O campo de descrição é obrigatório'),
  isPaid: z.boolean().optional(),
  paidAt: z.date().nullable().optional(),
  startDate: z.date().nullable(),
  dueDate: z.date().nullable().optional(),
  lateFeeType: z.nativeEnum(LateFeeTypeEnum).optional(),
  InterestTypeEnum: z.nativeEnum(InterestTypeEnum).optional(),
  customerId: z.string(),
  dueDay: z
    .string()
    .refine(
      (value) => {
        const numValue = Number(value);
        return !isNaN(numValue) && numValue >= 1 && numValue <= 31;
      },
      {
        message: 'O valor deve ser um número válido entre 1 e 31',
      },
    )
    .optional(),
  totalInstallments: z.string().refine(
    (value) => {
      const numValue = Number(value);
      return !isNaN(numValue) && numValue >= 1 && numValue <= 999;
    },
    {
      message: 'O valor deve ser um número válido entre 1 e 999',
    },
  ),
  interestAmount: z
    .string()
    .refine(
      (value) => {
        if (!value) return true;
        const numValue = Number(value);
        return !isNaN(numValue) && numValue >= 1 && numValue <= 100;
      },
      {
        message: 'O valor deve ser um número válido entre 1 e 100',
      },
    )
    .optional(),
  lateFeeAmount: z
    .string()
    .refine(
      (value) => {
        if (!value) return true;
        const numValue = Number(value);
        return !isNaN(numValue) && numValue >= 1 && numValue <= 999;
      },
      {
        message: 'O valor deve ser um número válido entre 1 e 999',
      },
    )
    .optional(),
});

export default function BillAddEdit() {
  const { billId } = useParams();
  const { createMutation, readMutation, updateMutation } = useBill();
  const navigate = useNavigate();

  const form = useForm<formProps>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      frequency: BillFrequencyEnum.ONCE,
      amount: 0,
      accountId: '',
      categoryId: '',
      description: '',
      isPaid: false,
      customerId: '',
      paidAt: null,
      totalInstallments: '1',
      startDate: new Date(),
      dueDate: null,
      interestAmount: '',
      lateFeeAmount: '',
      lateFeeType: '',
      interestType: '',
      dueDay: '',
    },
  });

  useEffect(() => {
    fetchBill();
  }, [billId]);

  useEffect(() => {
    if (form.getValues('isPaid')) {
      form.setValue('paidAt', new Date());
    } else {
      form.setValue('paidAt', null);
      form.clearErrors('paidAt');
    }
  }, [form.watch('isPaid')]);

  useEffect(() => {
    if (
      form.getValues('frequency') === BillFrequencyEnum.MONTHLY ||
      form.getValues('frequency') === BillFrequencyEnum.WEEKLY
    ) {
      form.setValue('dueDay', '1');
    } else {
      form.setValue('dueDay', '');
      form.clearErrors('dueDay');
    }

    if (form.getValues('frequency') === BillFrequencyEnum.ONCE) {
      form.setValue('dueDate', new Date());
    } else {
      form.setValue('dueDate', null);
    }
  }, [form.watch('frequency')]);

  const fetchBill = async () => {
    if (billId) {
      const data = await readMutation.mutateAsync(billId);
      form.reset({
        ...data,
        accountId: String(data.accountId),
        customerId: data.customerId ? String(data.customerId) : '',
        categoryId: String(data.categoryId),
        amount: Number(data.amount),
        dueDay: data.amount ? String(data.amount) : '',
        paidAt: data.paidAt ? new Date(data.paidAt) : null,
        totalInstallments: String(data.totalInstallments),
        startDate: new Date(data.startDate),
        dueDate: data.dueDate ? new Date(data.dueDate) : null,
        interestType: data.interestType ? data.interestType : '',
        interestAmount: data.interestAmount ? String(data.interestAmount) : '',
        lateFeeAmount: data.lateFeeAmount ? String(data.lateFeeAmount) : '',
        lateFeeType: data.lateFeeType ? data.lateFeeType : '',
        description: data.description ? data.description : '',
      });
    }
  };

  const onSubmit = async (values: any) => {
    try {
      const data = {
        ...values,
        accountId: Number(values.accountId),
        customerId: values.customerId ? Number(values.customerId) : null,
        categoryId: Number(values.categoryId),
        dueDay: values.dueDay ? Number(values.dueDay) : null,
        amount: values.amount,
        paidAt: values.paidAt ? values.paidAt : null,
        totalInstallments: Number(values.totalInstallments),
        dueDate: values.dueDate ? values.dueDate : null,
        lateFeeAmount: values.lateFeeAmount ? Number(values.lateFeeAmount) : 0,
        interestAmount: values.interestAmount
          ? Number(values.interestAmount)
          : 0,
      };
      if (!billId) {
        await createMutation.mutateAsync(data);
      } else {
        await updateMutation.mutateAsync({ id: billId, data });
      }
    } finally {
      navigate('/bills');
    }
  };

  const renderHeader = useMemo(() => {
    return (
      <CardHeader>
        <CardTitle>{!billId ? 'Adicionar conta' : 'Editar conta'}</CardTitle>
        {!billId && (
          <CardDescription>
            Insira todos os dados abaixo para cadastrar uma nova conta.
          </CardDescription>
        )}
      </CardHeader>
    );
  }, [billId]);

  const frequencySelect = useMemo(() => {
    return (
      <FormField
        control={form.control}
        name="frequency"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Frequência</FormLabel>
            <FormControl>
              <Select onValueChange={field.onChange} value={field.value}>
                <SelectTrigger>
                  <SelectValue placeholder="Selecione a frequência" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value={BillFrequencyEnum.ONCE}>
                      Única
                    </SelectItem>
                    <SelectItem value={BillFrequencyEnum.DAILY}>
                      Diario
                    </SelectItem>
                    <SelectItem value={BillFrequencyEnum.WEEKLY}>
                      Semanal
                    </SelectItem>
                    <SelectItem value={BillFrequencyEnum.MONTHLY}>
                      Mensal
                    </SelectItem>
                    <SelectItem value={BillFrequencyEnum.YEARLY}>
                      Anual
                    </SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    );
  }, [form]);

  const categoryCombobox = useMemo(() => {
    return (
      <FormField
        control={form.control}
        name="categoryId"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Categoria</FormLabel>
            <FormControl>
              <CategoryCombobox value={field.value} onChange={field.onChange} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    );
  }, [form]);

  const accountCombobox = useMemo(() => {
    return (
      <FormField
        control={form.control}
        name="accountId"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Conta bancária</FormLabel>
            <FormControl>
              <AccountCombobox value={field.value} onChange={field.onChange} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    );
  }, [form]);

  const customerCombobox = useMemo(() => {
    return (
      <FormField
        control={form.control}
        name="customerId"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Cliente</FormLabel>
            <FormControl>
              <CustomerCombobox value={field.value} onChange={field.onChange} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    );
  }, [form]);

  const paidDate = useMemo(() => {
    return (
      <FormField
        control={form.control}
        name="paidAt"
        render={({ field }) => (
          <FormItem className="w-full flex-1">
            <FormLabel>Data de pagamento</FormLabel>
            <Popover>
              <PopoverTrigger asChild>
                <FormControl>
                  <Button
                    disabled={!form.watch('isPaid')}
                    variant={'outline'}
                    className={cn(
                      'pl-3 text-left font-normal w-full',
                      !field.value && 'text-muted-foreground',
                    )}
                  >
                    {field.value ? (
                      format(field?.value, 'dd/MM/yyyy')
                    ) : (
                      <span>Selecione uma data</span>
                    )}
                    <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                  </Button>
                </FormControl>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                  mode="single"
                  selected={field.value || undefined}
                  onSelect={field.onChange}
                  disabled={(date) => date < new Date('1900-01-01')}
                  initialFocus
                />
              </PopoverContent>
            </Popover>
            <FormMessage />
          </FormItem>
        )}
      />
    );
  }, [form]);

  const startDate = useMemo(() => {
    return (
      <FormField
        control={form.control}
        name="startDate"
        render={({ field }) => (
          <FormItem className="w-full flex-1">
            <FormLabel>Data de inicio</FormLabel>
            <Popover>
              <PopoverTrigger asChild>
                <FormControl>
                  <Button
                    variant={'outline'}
                    className={cn(
                      'pl-3 text-left font-normal w-full',
                      !field.value && 'text-muted-foreground',
                    )}
                  >
                    {field.value ? (
                      format(field.value, 'dd/MM/yyyy')
                    ) : (
                      <span>Selecione uma data</span>
                    )}
                    <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                  </Button>
                </FormControl>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                  mode="single"
                  selected={
                    field.value instanceof Date ? field.value : undefined
                  }
                  onSelect={field.onChange}
                  disabled={(date) => date < new Date('1900-01-01')}
                  initialFocus
                />
              </PopoverContent>
            </Popover>
            <FormMessage />
          </FormItem>
        )}
      />
    );
  }, [form]);

  const dueDate = useMemo(() => {
    return (
      <FormField
        control={form.control}
        name="dueDate"
        disabled={form.watch('frequency') !== BillFrequencyEnum.ONCE}
        render={({ field }) => (
          <FormItem className="w-full flex-1">
            <FormLabel>Data de vencimento</FormLabel>
            <Popover>
              <PopoverTrigger asChild>
                <FormControl>
                  <Button
                    variant={'outline'}
                    disabled={
                      form.watch('frequency') !== BillFrequencyEnum.ONCE
                    }
                    className={cn(
                      'pl-3 text-left font-normal w-full',
                      !field.value && 'text-muted-foreground',
                    )}
                  >
                    {field.value ? (
                      format(field.value, 'dd/MM/yyyy')
                    ) : (
                      <span>Selecione uma data</span>
                    )}
                    <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                  </Button>
                </FormControl>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                  mode="single"
                  selected={field.value || undefined}
                  onSelect={field.onChange}
                  disabled={(date) => date < new Date('1900-01-01')}
                  initialFocus
                />
              </PopoverContent>
            </Popover>
            <FormMessage />
          </FormItem>
        )}
      />
    );
  }, [form]);

  const feeLate = useMemo(() => {
    return (
      <fieldset className="border p-2 w-full col-span-3 rounded-md grid grid-cols-2 gap-3">
        <legend className="text-sm  text-muted-foreground">
          Multa por atrasado
        </legend>
        <FormField
          control={form.control}
          name="lateFeeType"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Tipo</FormLabel>
              <FormControl>
                <Select onValueChange={field.onChange} value={field.value}>
                  <SelectTrigger>
                    <SelectValue placeholder="Selecione um tipo de multa" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value={LateFeeTypeEnum.FIXED}>
                        Fixa
                      </SelectItem>
                      <SelectItem value={LateFeeTypeEnum.PERCENTAGE}>
                        Porcentagem
                      </SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="lateFeeAmount"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Total multa</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  type="number"
                  step="any"
                  min="0"
                  max="999999"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </fieldset>
    );
  }, [form]);

  const insterest = useMemo(() => {
    return (
      <fieldset className="border p-2 w-full col-span-3 rounded-md grid grid-cols-2 gap-3">
        <legend className="text-sm  text-muted-foreground">Juros</legend>
        <FormField
          control={form.control}
          name="interestType"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Tipo de juros</FormLabel>
              <FormControl>
                <Select onValueChange={field.onChange} value={field.value}>
                  <SelectTrigger>
                    <SelectValue placeholder="Selecione um tipo de juros" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value={BillFrequencyEnum.ONCE}>
                        Juros simples
                      </SelectItem>
                      <SelectItem value={BillFrequencyEnum.DAILY}>
                        Juros composto
                      </SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="interestAmount"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                Total juros <span className="text-muted-foreground">(%)</span>
              </FormLabel>
              <FormControl>
                <Input {...field} type="number" step="any" min="0" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </fieldset>
    );
  }, [form]);

  const renderDetails = useMemo(() => {
    return (
      <>
        <div className="flex flex-row gap-2 justify-between text-sm">
          <span>Quantia</span>
          <span>{formatCurrency(form.watch('amount'))}</span>
        </div>
        <div className="flex flex-row gap-2 justify-between text-sm text-muted-foreground">
          <span className="text-muted-foreground">Juros</span>
          <span>{form.watch('interestAmount') || 0}%</span>
        </div>
        <div className="flex flex-row gap-2 justify-between text-sm text-muted-foreground">
          <span className="text-muted-foreground">Multa</span>
          <span>
            {form.watch('lateFeeType') === LateFeeTypeEnum.FIXED
              ? formatCurrency(form.watch('lateFeeAmount'))
              : `${form.watch('lateFeeAmount') || 0}%`}
          </span>
        </div>
        <hr />
        <div className="flex flex-row gap-2 justify-between text-md text-sm text-muted-foreground">
          <span className="text-muted-foreground">Valor total com multa</span>
          <span>R$ 99,00</span>
        </div>
        <div className="flex flex-row gap-2 justify-between text-md">
          <span className="text-muted-foreground">Valor total</span>
          <span>R$ 99,00</span>
        </div>
      </>
    );
  }, [
    form.watch('amount'),
    form.watch('lateFeeAmount'),
    form.watch('lateFeeType'),
    form.watch('interestAmount'),
  ]);

  return (
    <div className="grid auto-rows-max items-start md:gap-2 grid-cols-1 lg:col-span-3 gap-4 lg:grid-cols-[1fr_350px] lg:gap-4">
      <Card>
        {renderHeader}
        <CardContent>
          {readMutation.isLoading ? (
            <Loader />
          ) : (
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-8"
              >
                <div className="grid lg:grid-cols-3 gap-3">
                  <FormField
                    control={form.control}
                    name="isPaid"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Pago</FormLabel>
                        <FormControl>
                          <Switch
                            checked={field.value}
                            onCheckedChange={field.onChange}
                            className="block"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  {paidDate}
                </div>
                <div className="flex flex-col gap-5">
                  <div className="grid lg:grid-cols-3 gap-3">
                    <FormField
                      control={form.control}
                      name="description"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Descrição</FormLabel>
                          <FormControl>
                            <Input {...field} type="text" />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    {categoryCombobox}
                    {frequencySelect}
                    {accountCombobox}
                    <FormField
                      control={form.control}
                      name="amount"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Quantia</FormLabel>
                          <FormControl>
                            <NumericFormat
                              value={field.value}
                              onValueChange={(values) =>
                                field.onChange(values.floatValue)
                              }
                              customInput={Input}
                              prefix="R$ "
                              allowNegative={true}
                              thousandSeparator=","
                              decimalSeparator="."
                              fixedDecimalScale={true}
                              decimalScale={2}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="totalInstallments"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Parcelas</FormLabel>
                          <FormControl>
                            <Input {...field} type="number" min="1" max="999" />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="dueDay"
                      disabled={
                        form.watch('frequency') === BillFrequencyEnum.ONCE ||
                        form.watch('frequency') === BillFrequencyEnum.DAILY
                      }
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Dia de vencimento</FormLabel>
                          <FormControl>
                            <Input {...field} type="number" min="1" max="31" />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    {customerCombobox}
                    {startDate}
                    {dueDate}
                    {feeLate}
                    {insterest}
                  </div>
                </div>
                <div className="flex flex-row gap-2 items-center justify-end">
                  {!createMutation.isLoading && (
                    <Button type="button" variant="outline" asChild>
                      <Link to={'/bills'}>Cancelar</Link>
                    </Button>
                  )}
                  <Button
                    type="submit"
                    loading={
                      createMutation.isLoading || updateMutation.isLoading
                    }
                  >
                    Salvar
                  </Button>
                </div>
              </form>
            </Form>
          )}
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>Detalhes</CardTitle>
        </CardHeader>
        <CardContent className="flex flex-col gap-3">
          {renderDetails}
        </CardContent>
      </Card>
    </div>
  );
}
