import { Route, Routes } from 'react-router-dom';
import { CustomerBillsList } from './customer-bills-list';
import { Layout } from './_components/layout';
import { CustomerCheckout } from './customer-checkout';
import { Payment } from './payment';

export const CustomerBills = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<CustomerBillsList />} />
        <Route path="/payment/:paymentId" element={<Payment />} />
        <Route path="/bill/:billId/checkout" element={<CustomerCheckout />} />
        <Route
          path="/bill/:billId/installment/:installmentIndex/checkout"
          element={<CustomerCheckout />}
        />
      </Route>
    </Routes>
  );
};
